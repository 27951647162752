// Module Dependencies
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDarkMode } from 'features/ui/uiSlice';
import useNotifications from 'hooks/useNotifications';

// UI
import { SidebarLayout } from 'components/catalyst/sidebar-layout';
import SidebarMain from './SidebarMain';
import SidebarNavbar from './SidebarNavbar';
import NotificationsDialog from 'components/notifications/NotificationsDialog';

const MainLayout = () => {
  const { isDarkMode } = useSelector((state) => state.ui);
  
  const dispatch = useDispatch();

  const notificationsHandlers = useNotifications();

  const {
    showNotificationsModal,
    handleOpenNotificationsModal,
  } = notificationsHandlers;

  const handleToggleTheme = () => {
    dispatch(toggleDarkMode());
  };

  return (
    <SidebarLayout
      navbar={
        <SidebarNavbar
          isDarkMode={isDarkMode}
          toggleDarkMode={handleToggleTheme}
          handleOpenNotificationsModal={handleOpenNotificationsModal}
        />
      }
      sidebar={
        <SidebarMain
          isDarkMode={isDarkMode}
          toggleDarkMode={handleToggleTheme}
          showNotificationsModal={showNotificationsModal}
          handleOpenNotificationsModal={handleOpenNotificationsModal}
        />
      }
    >
      <Outlet />

      <NotificationsDialog {...notificationsHandlers} />
    </SidebarLayout>
  );
};

export default MainLayout;
