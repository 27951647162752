// Module dependencies
import { Controller } from "react-hook-form";
import validator from "validator";

// UI
import { Checkbox, CheckboxField } from "components/catalyst/checkbox";
import { ErrorMessage, Field, Label } from "components/catalyst/fieldset";
import { Input } from "components/catalyst/input";
import { Strong } from "components/catalyst/text";
import { Textarea } from "components/catalyst/textarea";

// Utils
import wording from "utils/wording";

const WorkInfoForm = ({
  register,
  control,
  errors,
}) => {
  const {
    CREATE_WO_WORK_INFORMATION,
    CREATE_WO_WORK_PERFORMED_LABEL,
    CREATE_WO_WORK_PERFORMED_PLACEHOLDER,
    CREATE_WO_AMOUNT_DUE_LABEL,
    CREATE_WO_AMOUNT_DUE_PLACEHOLDER,
    CREATE_WO_CASH_ON_DELIVERY,
    CREATE_WO_PICTURES_PROVIDED,
    ERROR_INVALID_AMOUNT,
  } = wording;
  
  return (
    <div className="space-y-4">
      <Strong>
        {CREATE_WO_WORK_INFORMATION}
      </Strong>

      <Field>
        <Label>
          {CREATE_WO_WORK_PERFORMED_LABEL}
        </Label>

        <Textarea 
          {...register('workPerformed')}
          rows={3}
          placeholder={CREATE_WO_WORK_PERFORMED_PLACEHOLDER}
        />
      </Field>

      <Field>
        <Label>{CREATE_WO_AMOUNT_DUE_LABEL}</Label>

        <Input
          type="number"
          {...register('amountDue', {
            valueAsNumber: true,
            validate: (value) => {
              return (
                validator.isNumeric(value?.toString()) &&
                parseFloat(value) >= 0
              ) || ERROR_INVALID_AMOUNT;
            },
          })}
          step="0.01"
          min='0'
          placeholder={CREATE_WO_AMOUNT_DUE_PLACEHOLDER}
        />

        {errors.amountDue && (
          <ErrorMessage>{ERROR_INVALID_AMOUNT}</ErrorMessage>
        )}
      </Field>

      <div className="flex flex-col gap-2">
        <CheckboxField>
          <Controller
            name="cashOnDelivery"
            control={control}
            render={({ field }) => (
              <Checkbox
                checked={field.value}
                onChange={(checked) => field.onChange(checked)}
              />
            )}
          />

          <Label>{CREATE_WO_CASH_ON_DELIVERY}</Label>
        </CheckboxField>
      </div>
    </div>
  );
};

export default WorkInfoForm;
