// UI
import { Badge } from 'components/catalyst/badge';
import { Strong, Text } from 'components/catalyst/text';

// Utils
import wording from 'utils/wording';

const WODashboardTotals = ({
  workOrderKPIs
}) => {
  const {
    totalWorkOrders,
    assignedWorkOrders,
    unassignedWorkOrders,
  } = workOrderKPIs;

  const {
    WORK_ORDER_DASH_TOTALS,
    GLOBAL_PERCENTAGE,
    GLOBAL_UNASSIGNED,
    GLOBAL_ASSIGNED,
  } = wording;

  return (
    <div className="py-4 flex flex-col w-full gap-2 md:pl-6 w-full md:w-[300px]">
      <Text>
        <Strong className='capitalize'>
          {WORK_ORDER_DASH_TOTALS}
        </Strong>
      </Text>

      <Strong className='capitalize text-5xl pb-1'>
        {totalWorkOrders}
      </Strong>

      <div className='flex items-center gap-2 py-1'>
        <Badge color='green' className='w-min px-2'>
          {`${((assignedWorkOrders / totalWorkOrders) * 100).toFixed(1)}${GLOBAL_PERCENTAGE}`}
        </Badge>

        <Text>
          {`${GLOBAL_ASSIGNED} (${assignedWorkOrders})`}
        </Text>
      </div>

      <div className='flex items-center gap-2 py-1'>
        <Badge color='yellow' className='w-min px-2'>
          {`${((unassignedWorkOrders / totalWorkOrders) * 100).toFixed(1)}${GLOBAL_PERCENTAGE}`}
        </Badge>

        <Text>
          {`${GLOBAL_UNASSIGNED} (${unassignedWorkOrders})`}
        </Text>
      </div>


    </div>
  );
};

export default WODashboardTotals;
