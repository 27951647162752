const { endpoints } = require("config/api");
const { axiosInstance } = require("config/axios");


const getNotifications = async () => {
  const { data } = await axiosInstance.get(
    endpoints.notifications.base
  );

  return data;
};

const markNotificationAsRead = async (notificationId) => {
  const { data } = await axiosInstance.put(
    endpoints.notifications.markAsRead(notificationId)
  );

  return data;
};

const markAllNotificationsAsRead = async () => {
  await axiosInstance.put(
    endpoints.notifications.markAllAsRead
  );

  return true;
};

const notificationsService = {
  getNotifications,
  markNotificationAsRead,
  markAllNotificationsAsRead,
};

export default notificationsService;
