import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import projectReducer from '../features/projects/projectSlice';
import workOrderReducer from '../features/workOrders/workOrderSlice';
import userReducer from '../features/users/userSlice';
import templateReducer from '../features/templates/templateSlice';
import uiReducer from '../features/ui/uiSlice';
import companyReducer from '../features/company/companySlice';
import notificationsReducer from '../features/notifications/notificationsSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    projects: projectReducer,
    workOrders: workOrderReducer,
    users: userReducer,
    templates: templateReducer,
    ui: uiReducer,
    company: companyReducer,
    notifications: notificationsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
