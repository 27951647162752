// Module dependencies
import useCreateWorkOrderForm from 'hooks/useCreateWorkOrderForm';

// UI
import Breadcrumb from 'components/Breadcrumb';
import { Button } from 'components/catalyst/button';
import { Heading } from 'components/catalyst/heading';
import LoadingButton from 'components/common/LoadingButton';
import { Select } from 'components/catalyst/select';
import { Text } from 'components/catalyst/text';
import { ChevronLeftIcon, PlusIcon } from '@heroicons/react/20/solid';
import CreateWorkOrderForm from 'components/workOrder/CreateWorkOrderForm';

// Utils
import wording from 'utils/wording';

const CreateWorkOrderTemplate = () => {
  const createWorkOrderHandlers = useCreateWorkOrderForm();

  const {
    handleSubmit,
    onSubmit,
    handleCancelCreate,
    isValid,
    isLoading,
    handleTemplateChange,
    selectedTemplate,
    workOrdersTemplates,
    handleCreateTemplate,
    isUploading,
  } = createWorkOrderHandlers;

  const {
    CREATE_WO_CREATE_WORK_ORDER,
    CREATE_WO_CREATING_WORK_ORDER,
    CREATE_WO_WORK_ORDERS,
    CREATE_WO_CANCEL,
    CREATE_WO_TEMPLATE_SUBTITLE,
    CREATE_WO_TEMPLATE_CREATE,
  } = wording;

  const pages = [
    { name: CREATE_WO_WORK_ORDERS, href: '/work-orders', current: false },
    { name: CREATE_WO_CREATE_WORK_ORDER, href: '/work-orders/new', current: false },
  ];

  return (
    <div className='flex flex-col gap-6'>
      <Breadcrumb pages={pages} />

      <div className='flex flex-col md:flex-row md: justify-between gap-4'>
        <Heading className='text-xl md:text-2xl'>{CREATE_WO_CREATE_WORK_ORDER}</Heading>

        <div className='flex md:justify-end items-center gap-2'>
          <LoadingButton
            color='green'
            type='submit'
            disabled={!isValid || isLoading || isUploading}
            isLoading={isLoading || isUploading}
            label={CREATE_WO_CREATE_WORK_ORDER}
            loadingLabel={CREATE_WO_CREATING_WORK_ORDER}
            className='whitespace-nowrap'
            onClick={handleSubmit(onSubmit)}
          />

          <Button
            outline
            onClick={handleCancelCreate}
          >
            <ChevronLeftIcon />
            {CREATE_WO_CANCEL}
          </Button>
        </div>
      </div>

      <div className='flex flex-col md:flex-row md:justify-between md:items-center gap-2'>
        <Text>{CREATE_WO_TEMPLATE_SUBTITLE}</Text>

        <div className='flex justify-end items-center gap-2'>
          {selectedTemplate && workOrdersTemplates.length > 0 && (
            <Select
              value={selectedTemplate._id}
              onChange={handleTemplateChange}
            >
              {workOrdersTemplates.map(({ name, _id }) => (
                <option 
                  key={_id} 
                  value={_id}
                >
                  {name}
                </option>
              ))}
            </Select>
          )}

          <Button
            outline
            onClick={handleCreateTemplate}
            className='whitespace-nowrap'
          >
            <PlusIcon />
            {CREATE_WO_TEMPLATE_CREATE}
          </Button>
        </div>
      </div>

      <CreateWorkOrderForm createWorkOrderHandlers={createWorkOrderHandlers} />
    </div>
  );
};

export default CreateWorkOrderTemplate;
