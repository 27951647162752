import { axiosInstance } from "../config/axios";
import { endpoints } from "../config/api";
import { formatCurrency, formatDate } from "./commons";

const generatePDF = async (workOrder) => {
  try {
    console.log('Generating PDF for work order:', workOrder._id);
    console.log('Work order data:', workOrder);

    // Make API call to generate PDF using axios instance
    const response = await axiosInstance({
      url: `${endpoints.workOrders.workOrder(workOrder._id)}/pdf`,
      method: 'GET',
      responseType: 'blob' // Important for handling PDF response
    });

    console.log('PDF generation response received');

    // Format filename
    const formatFileName = (name) => {
      return name?.replace(/[^a-zA-Z0-9\s-]/g, '')
                .replace(/\s+/g, '-')
                .toLowerCase() || 'unnamed-client';
    };

    const formatFileDate = (date) => {
      return date ? new Date(date).toISOString().split('T')[0] : 'no-date';
    };

    // Create filename
    const fileName = `WorkOrder-${formatFileName(workOrder.client?.name)}-${formatFileDate(workOrder.dateRequested)}.pdf`;

    // Create download link
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    
    // Append to body, click and remove
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    
    // Clean up the URL
    window.URL.revokeObjectURL(url);

  } catch (error) {
    console.error('Error generating PDF:', error);
    console.error('Error details:', error.response?.data);
    throw new Error('Failed to generate PDF: ' + (error.response?.data?.message || error.message));
  }
};

// Helper function to format template field values
const formatTemplateValue = (value, type) => {
  if (value === undefined || value === null || value === '') {
    return 'N/A';
  }

  switch (type) {
    case 'date':
      return formatDate(value);
    case 'datetime':
    case 'datetime-local':
      return new Date(value).toLocaleString();
    case 'checkbox':
      return value ? 'Yes' : 'No';
    case 'checkbox-group':
      return Array.isArray(value) ? value.join(', ') : value;
    case 'select':
      return value.toString();
    case 'number':
      return value.toString();
    case 'textarea':
    case 'text':
      return value.toString();
    default:
      return value.toString();
  }
};

const getUpcomingDeadlines = (workOrders, days) => {
  if (!workOrders?.length) return [];
  
  const today = new Date();
  const sevenDaysFromNow = new Date();
  sevenDaysFromNow.setDate(today.getDate() + Number(days));

  return workOrders
  .filter(wo => {
    if (!wo.endDate || wo.status === 'completed') return false;
    const endDate = new Date(wo.endDate);
      return endDate > today && endDate <= sevenDaysFromNow;
    })
    .sort((a, b) => new Date(a.endDate) - new Date(b.endDate));
};

const calculateWorkOrderKPIs = (workOrders) => {
  if (!workOrders?.length) return {
    totalWorkOrders: 0,
    workOrdersByStatus: {
      pending: 0,
      'in-progress': 0,
      completed: 0,
      cancelled: 0,
      'on-schedule': 0,
    },
    workOrdersByPriority: {
      high: 0,
      medium: 0,
      low: 0,
      undefined: 0,
    },
    assignedWorkOrders: 0,
    unassignedWorkOrders: 0,
    assignedUsers: {}
  };

  const stats = workOrders.reduce((acc, wo) => {
    acc.totalWorkOrders++;

    if (wo.status in acc.workOrdersByStatus) {
      acc.workOrdersByStatus[wo.status]++;
    }

    const priority = wo.inspectionPriority?.toLowerCase();
    if (priority && ['high', 'medium', 'low'].includes(priority)) {
      acc.workOrdersByPriority[priority]++;
    } else {
      acc.workOrdersByPriority.undefined++;
    }

    if (wo.assignedTo) {
      acc.assignedWorkOrders++;
      acc.assignedUsers[wo.assignedTo] = (acc.assignedUsers[wo.assignedTo] || 0) + 1;
    } else {
      acc.unassignedWorkOrders++;
    }

    return acc;
  }, {
    totalWorkOrders: 0,
    workOrdersByStatus: {
      pending: 0,
      'in-progress': 0,
      completed: 0,
      cancelled: 0,
      'on-schedule': 0
    },
    workOrdersByPriority: {
      high: 0,
      medium: 0,
      low: 0,
      undefined: 0,
    },
    assignedWorkOrders: 0,
    unassignedWorkOrders: 0,
    assignedUsers: {}
  });

  return stats;
};

export {
  generatePDF,
  getUpcomingDeadlines,
  calculateWorkOrderKPIs,
};
