// Module dependencies
// import { Link } from 'react-router-dom';

// UI
import { DescriptionDetails, DescriptionList, DescriptionTerm } from 'components/catalyst/description-list';
import { Heading } from 'components/catalyst/heading';
import { Select } from 'components/catalyst/select';
// import { TextLink } from 'components/catalyst/text';
import Card from 'components/common/Card';

// Utils
import wording from 'utils/wording';
import { Badge } from 'components/catalyst/badge';
import { inspectionOptions } from 'components/workOrder/utils';
import StatusBadge from 'components/StatusBadge';

const AssignmentCard = ({
  workOrder,
  // projects,
  isEditing,
  // technicians, // TODO: Enable once technician restrictions are reinstated.
  users,
  register,
}) => {
  const {
    WO_DETAIL_ASSIGNMENT,
    WO_DETAIL_ASSIGNED_TO,
    WO_DETAIL_PROJECT,
    WO_DETAIL_INSPECTION_PRIORITY,
    GLOBAL_UNASSIGNED,
    // GLOBAL_NO_PROJECT,
    GLOBAL_COMING_SOON,
  } = wording;

  return (
    <Card className='break-inside-avoid h-min'>
      <Heading level={2} className='pb-2 text-xl md:text-2xl'>
        {WO_DETAIL_ASSIGNMENT}
      </Heading>

      <DescriptionList>
        <DescriptionTerm>
          {WO_DETAIL_ASSIGNED_TO}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <Select
              {...register("assignedTo")}
            >
              <option value={''}>{''}</option>

              {users.map(user => (
                <option key={user.id} value={user.id}>
                  {`${user.firstName} ${user.lastName}`}
                </option>
              ))}
            </Select>
          ) : (
            <>
              {workOrder?.assignedTo
                ? `${workOrder.assignedTo?.firstName} ${workOrder.assignedTo?.lastName}`
                : GLOBAL_UNASSIGNED
              }
            </>
          )}
        </DescriptionDetails>

        <DescriptionTerm>
          {WO_DETAIL_PROJECT}
        </DescriptionTerm>

        <DescriptionDetails>
          {/* Enable when projects feature get released */}
          {/* {isEditing ? (
            <Select
              name="assignedToProject"
              value={editedData?.assignedToProject}
              onChange={handleChange}
            >
              {projects.map(option => (
                <option key={option._id} value={option._id}>
                  {option.name}
                </option>
              ))}
            </Select>
          ) : (
            <Link to={`/projects/${workOrder?.assignedToProject?._id}`}>
              <TextLink>
                {workOrder?.assignedToProject
                  ? workOrder?.assignedToProject.name
                  : GLOBAL_NO_PROJECT
                }
              </TextLink>
            </Link>
          )} */}

          <Badge color='zinc' className='capitalize'>
            {GLOBAL_COMING_SOON}
          </Badge>
        </DescriptionDetails>

        <DescriptionTerm>
          {WO_DETAIL_INSPECTION_PRIORITY}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <Select
              {...register('inspectionPriority')}
            >
              <option value={''}>{''}</option>
    
              {inspectionOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          ) : (
            <StatusBadge status={workOrder?.inspectionPriority}/>
          )}
        </DescriptionDetails>
      </DescriptionList>
    </Card>
  );
};

export default AssignmentCard;
