// UI
import { DescriptionDetails, DescriptionList, DescriptionTerm } from 'components/catalyst/description-list';
import { Heading } from 'components/catalyst/heading';
import { Input } from 'components/catalyst/input';
import Card from 'components/common/Card';

// Utils
import wording from 'utils/wording';

const ClientInformationCard = ({
  workOrder,
  isEditing,
  register,
}) => {
  const {
    WO_DETAIL_CLIENT_INFORMATION,
    WO_DETAIL_NAME,
    WO_DETAIL_EMAIL,
    WO_DETAIL_PHONE,
  } = wording;

  return (
    <Card>
      <Heading level={2} className='pb-2 text-xl md:text-2xl'>
        {WO_DETAIL_CLIENT_INFORMATION}
      </Heading>

      <DescriptionList>
        <DescriptionTerm>
          {WO_DETAIL_NAME}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <Input
              type="text"
              {...register('client.name')}
            />
          ) : (
            <>{workOrder?.client?.name}</>
          )}
        </DescriptionDetails>

        <DescriptionTerm>
          {WO_DETAIL_EMAIL}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <Input
              type="email"
              {...register('client.email')}
            />
          ) : (
            <>{workOrder?.client?.email}</>
          )}
        </DescriptionDetails>

        <DescriptionTerm>
          {WO_DETAIL_PHONE}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <Input
              type="tel"
              {...register('client.phone')}
            />
          ) : (
            <>{workOrder?.client?.phone}</>
          )}
        </DescriptionDetails>
      </DescriptionList>
  </Card>
  );
};

export default ClientInformationCard;
