import { parseISO, format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import wording from "./wording";

const { GLOBAL_NA } = wording;

const formatCurrency = (amount) => {
  if (amount == null || isNaN(amount)) {
    return '-';
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(amount);
};

const formatDate = (date) => {
  if (!date) return GLOBAL_NA;

  try {
    const parsedDate = parseISO(date);

    const zonedDate = toZonedTime(parsedDate, "UTC");

    const output = format(zonedDate, "M/d/yyyy");

    return output;
  } catch (error) {
    console.error("Format error", error);
    return GLOBAL_NA;
  }
};

const formatFullDate = (date) => {
  if (!date) return GLOBAL_NA;

  try {
    const parsedDate = parseISO(date);
    const zonedDate = toZonedTime(parsedDate, "UTC");

    return format(zonedDate, "MMM d, yyyy - h:mm a");
  } catch (error) {
    console.error("Format error", error);
    return GLOBAL_NA;
  }
};

const formatDateForInput = (dateString) => {
  if (!dateString) return '';
  return new Date(dateString).toISOString().split('T')[0];
};

const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

const getErrorMessage = (error) => {
  return (
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString()
  );
};

const getStatusColor = (status) => {
  const colors = {
    'pending': {
      bg: 'bg-yellow-100',
      text: 'text-yellow-800',
      border: 'border-yellow-200'
    },
    'in-progress': {
      bg: 'bg-blue-100',
      text: 'text-blue-800',
      border: 'border-blue-200'
    },
    'completed': {
      bg: 'bg-green-100',
      text: 'text-green-800',
      border: 'border-green-200'
    },
    'cancelled': {
      bg: 'bg-red-100',
      text: 'text-red-800',
      border: 'border-red-200'
    }
  };

  return colors[status] || colors['pending'];
};

const getStatusBadgeClasses = (status) => {
  const { bg, text } = getStatusColor(status);
  return `inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${bg} ${text}`;
};

const generateInitials = (userName) => {
  if (!userName || typeof userName !== 'string') return '';

  const words = userName.split(/\s+|&/).filter(Boolean);

  if (words.length === 1) {
    return words[0].charAt(0).toUpperCase();
  }

  return (words[0].charAt(0) + words[words.length - 1].charAt(0)).toUpperCase();
};

export {
  formatCurrency,
  formatDate,
  formatFullDate,
  formatDateForInput,
  formatPhoneNumber,
  getErrorMessage,
  getStatusColor,
  getStatusBadgeClasses,
  generateInitials,
}
