import { Strong } from "components/catalyst/text";

const Card = ({ 
  title,
  titleActions,
  children, 
  actions,
  className = '' 
}) => {
  return (
    <div className={`bg-white dark:bg-zinc-900 border border-gray-200 dark:border-gray-800 shadow-xs rounded-lg ${className}`}>
      {title && (
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-between items-center">
          <Strong className='text-lg'>
            {title}
          </Strong>

          {titleActions}
        </div>
      )}
      <div className="px-4 py-5 sm:p-6">
        {children}
      </div>
      {actions && (
        <div className="px-4 py-4 sm:px-6 border-t border-gray-200 bg-gray-50">
          {actions}
        </div>
      )}
    </div>
  );
};

export default Card;
