// UI
import { Select } from 'components/catalyst/select';
import { WORK_ORDER_STATUSES } from 'components/workOrder/utils';

const StatusSelectRegister = ({
  register
}) => {
  const statusOptions = [
    { value: WORK_ORDER_STATUSES.PENDING, label: 'Pending' },
    { value: WORK_ORDER_STATUSES.ON_SCHEDULE, label: 'On Schedule' },
    { value: WORK_ORDER_STATUSES.IN_PROGRESS, label: 'In Progress' },
    { value: WORK_ORDER_STATUSES.COMPLETED, label: 'Completed' },
    { value: WORK_ORDER_STATUSES.CANCELLED, label: 'Cancelled' },
  ];

  return (
    <Select
      name={'status'}
      {...register('status')}
    >
      {statusOptions.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};

export default StatusSelectRegister;
