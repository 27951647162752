// Module dependencies
import { Controller } from "react-hook-form";

// UI
import { Checkbox, CheckboxField } from "components/catalyst/checkbox";
import { Field, Label, ErrorMessage } from "components/catalyst/fieldset";
import { Input } from "components/catalyst/input";
import { Select } from "components/catalyst/select";
import { Textarea } from "components/catalyst/textarea";
import { Badge } from "components/catalyst/badge";
import { Button } from "components/catalyst/button";
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

// Utils
import wording from "utils/wording";
import { DescriptionDetails, DescriptionList, DescriptionTerm } from "components/catalyst/description-list";
import { Strong, Text } from "components/catalyst/text";

const {
  ERROR_REQUIRED_FIELD,
  TEMPLATE_FORM_DELETE_FIELD,
  TEMPLATE_FORM_UPDATE_FIELD,
  TEMPLATE_FORM_FIELD_NAME,
  TEMPLATE_FORM_FIELD_OPTIONS,
  TEMPLATE_FORM_FIELD_PLACEHOLDER,
  TEMPLATE_FORM_FIELD_TYPE,
  GLOBAL_DASH,
  GLOBAL_YES,
  GLOBAL_NO,
} = wording;

const templateKeys = ['service', 'materials', 'details'];
const filedTypes = ['text', 'datetime-local', 'datetime', 'select', 'textarea', 'checkbox', 'checkbox-group'];
const typesWithPlaceholder = [];
// const typesWithPlaceholder = ['text', 'textarea'];
const typesWithOptions = ['select', 'checkbox-group'];

const renderFormFields = (fields, templateKey, register, control, errors) => {
  return fields.map((field) => {
    const { name, label, type, options, placeholder, required } = field;

    switch (type) {
      case "text":
        return (
          <Field key={name}>
            <Label>
              {label}
            </Label>

            <Input
              type={type}
              {...register(
                `templateData.${templateKey}.${name}`,
                required ? { required: ERROR_REQUIRED_FIELD } : { }
              )}
              placeholder={placeholder}
            />

            {errors[name] && (
              <ErrorMessage>{errors[name]?.message}</ErrorMessage>
            )}
          </Field>
        );

      case "datetime-local":
        return (
          <Field key={name}>
            <Label>
              {label}
            </Label>

            <Input
              type={type}
              {...register(
                `templateData.${templateKey}.${name}`,
                required ? { required: ERROR_REQUIRED_FIELD } : { }
              )}
              placeholder={placeholder}
            />

            {errors[name] && (
              <ErrorMessage>{errors[name]?.message}</ErrorMessage>
            )}
          </Field>
        );

      case "datetime":
        return (
          <Field key={name}>
            <Label>
              {label}
            </Label>

            <Input
              type={type}
              {...register(
                `templateData.${templateKey}.${name}`,
                required ? { required: ERROR_REQUIRED_FIELD } : { }
              )}
              placeholder={placeholder}
            />

            {errors[name] && (
              <ErrorMessage>{errors[name]?.message}</ErrorMessage>
            )}
          </Field>
        );

      case "select":
        return (
          <Field key={name}>
            <Label>
              {label}
            </Label>

            <Select
              {...register(
                `templateData.${templateKey}.${name}`,
                required ? { required: ERROR_REQUIRED_FIELD } : {}
              )}
            >
              <option value="">{""}</option>

              {options.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </Select>

            {errors[name] && (
              <ErrorMessage>{errors[name]?.message}</ErrorMessage>
            )}
          </Field>
        );

      case "textarea":
        return (
          <Field key={name}>
            <Label>
              {label}
            </Label>

            <Textarea
              {...register(
                `templateData.${templateKey}.${name}`,
                required ? { required: ERROR_REQUIRED_FIELD } : { }
              )}
              rows={3}
              placeholder={placeholder}
            />

            {errors[name] && (
              <ErrorMessage>{errors[name]?.message}</ErrorMessage>
            )}
          </Field>
        );

      case "checkbox":
        return (
          <CheckboxField key={name}>
            <Controller
              name={`templateData.${templateKey}.${name}`}
              control={control}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onChange={(checked) => field.onChange(checked)}
                />
              )}
            />

            <Label>
              {label}
            </Label>
          </CheckboxField>
        );

      case "checkbox-group":
        return (
          <Field key={name}>
            <Label>
              {label}
            </Label>

            <div className="grid grid-cols-2 gap-2 pt-2">
              {options.map((option) => (
                <CheckboxField key={option.label}>
                  <Controller
                    name={`templateData.${templateKey}.${name}.${option.value}`}
                    control={control}
                    render={({ field }) => (
                      <Checkbox checked={field.value} onChange={(checked) => field.onChange(checked)} />
                    )}
                  />
                  <Label className="capitalize">{option.label}</Label>
                </CheckboxField>
              ))}
            </div>
          </Field>
        );

      default:
        return { };
    }
  });
};

const renderDetailFormFields = (field, templateKey, register, control, errors) => {
  const { name, type, options, placeholder, required } = field;

  switch (type) {
    case "text":
      return (
        <Input
          type={type}
          {...register(
            `templateData.${templateKey}.${name}`,
            required ? { required: ERROR_REQUIRED_FIELD } : { }
          )}
          placeholder={placeholder}
        />
      );

    case "datetime-local":
      return (
        <Input
          type={type}
          {...register(
            `templateData.${templateKey}.${name}`,
            required ? { required: ERROR_REQUIRED_FIELD } : { }
          )}
          placeholder={placeholder}
        />
      );

    case "datetime":
      return (
        <Input
          type={type}
          {...register(
            `templateData.${templateKey}.${name}`,
            required ? { required: ERROR_REQUIRED_FIELD } : { }
          )}
          placeholder={placeholder}
        />
      );

    case "select":
      return (
        <Select
          {...register(
            `templateData.${templateKey}.${name}`,
            required ? { required: ERROR_REQUIRED_FIELD } : {}
          )}
        >
          <option value="">{""}</option>

          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </Select>
      );

    case "textarea":
      return (
        <Textarea
          {...register(
            `templateData.${templateKey}.${name}`,
            required ? { required: ERROR_REQUIRED_FIELD } : { }
          )}
          rows={3}
          placeholder={placeholder}
        />
      );

    case "checkbox":
      return (
        <Controller
          name={`templateData.${templateKey}.${name}`}
          control={control}
          render={({ field }) => (
            <Checkbox
              checked={field.value}
              onChange={(checked) => field.onChange(checked)}
            />
          )}
        />
      );

    case "checkbox-group":
      return (
        <div className="grid grid-cols-2 gap-2 pt-2">
          {options.map((option) => (
            <CheckboxField key={option.label}>
              <Controller
                name={`templateData.${templateKey}.${name}.${option.value}`}
                control={control}
                render={({ field }) => (
                  <Checkbox checked={field.value} onChange={(checked) => field.onChange(checked)} />
                )}
              />
              <Label className="capitalize">{option.label}</Label>
            </CheckboxField>
          ))}
        </div>
      );

    default:
      return { };
  }
};

const renderDetailValues = (field, templateKey, workOrder, templateOriginal) => {
  const { name, type } = field;

  const templateData = workOrder?.templateData?.[templateKey]?.[name];

  if (type === "checkbox") {
    return templateData
      ? GLOBAL_YES
      : GLOBAL_NO
  } else if (type === "checkbox-group" && templateData && typeof templateData === "object") {
    const templateField = templateOriginal?.[templateKey]?.find((item) => item.name === name);

    return (
      <div className="flex gap-1 flex-wrap">
        {Object.entries(templateData).map(([key, value]) => {
          if (!value) return null;

          const optionLabel = templateField?.options?.find((opt) => opt.value === key)?.label || key;

          return (
            <Badge color="yellow" key={key}>
              {optionLabel}
            </Badge>
          );
        })}
      </div>
    )
  } else if (type === "select") {
    const templateField = templateOriginal?.[templateKey]?.find((item) => item.name === name);

    const optionLabel = templateField?.options?.find(
      (opt) => opt.value === templateData
    )?.label || templateData || GLOBAL_DASH;

    return optionLabel;
  } else {
    return templateData || GLOBAL_DASH
  }
};

const renderTemplateFormFields = (fields, templateKey, removeFieldFromTemplate, handleOpenNewFieldDialog) => {
  return fields.map((field) => {
    const { name, label, type, options, placeholder, _id } = field;

    const FieldWrapper = (
      <div key={name} className='bg-white dark:bg-zinc-900 p-4 border border-gray-200 dark:border-gray-800 rounded-lg break-inside-avoid'>
        <div className='flex justify-between items-center pb-4'>
          <Text>
            <Strong>
              {label} 
            </Strong>
          </Text>

          <div className="space-x-2">
            <Button outline onClick={() => handleOpenNewFieldDialog(templateKey, field)}>
              <PencilIcon className="stroke-blue-500" />
              {/* {TEMPLATE_FORM_UPDATE_FIELD} */}
            </Button>

            <Button outline onClick={() => removeFieldFromTemplate(_id, templateKey)}>
              <TrashIcon className="stroke-red-500" />
              {/* {TEMPLATE_FORM_DELETE_FIELD} */}
            </Button>
          </div>
        </div>

        <DescriptionList>
          <DescriptionTerm>{TEMPLATE_FORM_FIELD_NAME}</DescriptionTerm>
          <DescriptionDetails>{label}</DescriptionDetails>

          <DescriptionTerm>{TEMPLATE_FORM_FIELD_TYPE}</DescriptionTerm>
          <DescriptionDetails>
            <Badge className='capitalize' color="blue">
              {type}
            </Badge>
          </DescriptionDetails>

          {typesWithPlaceholder.includes(type) && (
            <>
              <DescriptionTerm>{TEMPLATE_FORM_FIELD_PLACEHOLDER}</DescriptionTerm>
              <DescriptionDetails>{placeholder}</DescriptionDetails>
            </>
          )}

          {typesWithOptions.includes(type) && (
            <>
            <DescriptionTerm>{TEMPLATE_FORM_FIELD_OPTIONS}</DescriptionTerm>
              <DescriptionDetails>
                <div className="flex flex-col">
                  {options.map((option) => (
                    <Text key={option.value}>
                      {option.label}
                    </Text>
                  ))}
                </div>
              </DescriptionDetails>
            </>
          )}
        </DescriptionList>
      </div>
    );

    return FieldWrapper;
  });
};

export {
  renderFormFields,
  renderDetailFormFields,
  renderDetailValues,
  renderTemplateFormFields,
  templateKeys,
  filedTypes,
  typesWithPlaceholder,
  typesWithOptions,
};
