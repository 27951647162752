// UI
import { Button } from "components/catalyst/button";
import { Strong, Text } from "components/catalyst/text";
import { PlusIcon } from '@heroicons/react/20/solid';

// Utils
import { renderTemplateFormFields } from "utils/form-utils";
import wording from "utils/wording";

const WOTemplateDinamicCard = ({
  selectedTemplate,
  templateKey,
  handleOpenNewFieldDialog,
  removeFieldFromTemplate,
}) => {
  const {
    CREATE_WO_DINAMIC_SERVICE,
    CREATE_WO_DINAMIC_MATERIALS,
    CREATE_WO_DINAMIC_DETAILS,
    TEMPLATE_FORM_ADD_FIELD,
    TEMPLATE_FORM_NO_FIELD,
  } = wording;

  const getCardTitle = () => {
    const keyToTitleMapper ={
      service: CREATE_WO_DINAMIC_SERVICE,
      materials: CREATE_WO_DINAMIC_MATERIALS,
      details: CREATE_WO_DINAMIC_DETAILS,
    }

    return keyToTitleMapper[templateKey] || keyToTitleMapper.details;
  };

  return (
    <div className='space-y-4'>
      <div className='flex justify-between items-center'>
        <Strong className='text-lg'>
          {getCardTitle()}
        </Strong>

        <Button
          outline
          onClick={() => handleOpenNewFieldDialog(templateKey)}
        >
          <PlusIcon className='fill-blue-500' />
          {TEMPLATE_FORM_ADD_FIELD}
        </Button>
      </div>

      {selectedTemplate[templateKey].length > 0 ? (
        <div className='bg-white dark:bg-zinc-900 rounded-lg w-full'>
          <div className='columns-1 md:columns-2 gap-4 space-y-4'>
            {renderTemplateFormFields(
              selectedTemplate[templateKey],
              templateKey,
              removeFieldFromTemplate,
              handleOpenNewFieldDialog
            )}
          </div>
        </div>
      ) : (
        <Text>
          {TEMPLATE_FORM_NO_FIELD}
        </Text>
      )}
    </div>
);
};

export default WOTemplateDinamicCard;
