// Module dependencies
import { Autocomplete } from "@react-google-maps/api";
import validator from "validator";

// UI
import { ErrorMessage, Field, Label } from "components/catalyst/fieldset";
import { Input } from "components/catalyst/input";
import { Strong } from "components/catalyst/text";
import Map from "components/common/Map";
import Loader from "components/Loader";

// Utils
import wording from "utils/wording";

const ClientInformationForm = ({
  isLoaded,
  mapCenter,
  onLoad,
  onPlaceChanged,
  handleMapLoad,
  watch,
  register,
  errors,
}) => {
  const {
    CREATE_WO_CLIENT,
    CREATE_WO_NAME_LABEL,
    CREATE_WO_NAME_PLACEHOLDER,
    CREATE_WO_EMAIL_LABEL,
    CREATE_WO_EMAIL_PLACEHOLDER,
    CREATE_WO_PHONE_LABEL,
    CREATE_WO_PHONE_PLACEHOLDER,
    CREATE_WO_ADDRESS_LABEL,
    CREATE_WO_ADDRESS_PLACEHOLDER,
    ERROR_REQUIRED_FIELD,
    ERROR_INVALID_EMAIL,
    ERROR_INVALID_PHONE,
  } = wording;
  
  return (
    <div className="space-y-4">
      <Strong>
        {CREATE_WO_CLIENT}
      </Strong>

      <Field>
        <Label>{CREATE_WO_NAME_LABEL}</Label>

        <Input
          type="text"
          placeholder={CREATE_WO_NAME_PLACEHOLDER}
          {...register('client.name', {
            required: true,
          })}
        />

        {errors.client?.name?.type === 'required' && (
          <ErrorMessage>{ERROR_REQUIRED_FIELD}</ErrorMessage>
        )}
      </Field>

      <Field>
        <Label>{CREATE_WO_EMAIL_LABEL}</Label>

        <Input
          type="email"
          placeholder={CREATE_WO_EMAIL_PLACEHOLDER}
          {...register("client.email", {
            validate: (value) =>
              validator.isEmpty(value) || validator.isEmail(value) || ERROR_INVALID_EMAIL,
          })}
        />

        {errors.client?.email && (
          <ErrorMessage>{errors.client.email.message}</ErrorMessage>
        )}
      </Field>

      <Field>
        <Label>{CREATE_WO_PHONE_LABEL}</Label>

        <Input
          type="tel"
          placeholder={CREATE_WO_PHONE_PLACEHOLDER}
          {...register("client.phone", {
            validate: (value) =>
              validator.isEmpty(value) || validator.isMobilePhone(value, "any") || ERROR_INVALID_PHONE,
          })}
        />

        {errors.client?.phone && (
          <ErrorMessage>{errors.client.phone.message}</ErrorMessage>
        )}
      </Field>

      <div className="space-y-2">
        <div className="space-y-4">
          <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
          >
            <Field>
              <Label>{CREATE_WO_ADDRESS_LABEL}</Label>

              <Input
                type="text"
                placeholder={CREATE_WO_ADDRESS_PLACEHOLDER}
                {...register('address.formattedAddress', {
                  required: true,
                })}
              />

              {errors.address?.formattedAddress?.type === 'required' && (
                <ErrorMessage>{ERROR_REQUIRED_FIELD}</ErrorMessage>
              )}
            </Field>
          </Autocomplete>
          
          <div className="h-[300px] rounded-lg overflow-hidden border border-gray-200 dark:border-gray-800">
            {!isLoaded ? (
              <div className="h-[300px] flex items-center justify-center">
                <Loader /> 
              </div>
            ) : (
              <div style={{ height: '100%', width: '100%' }}>
                <Map
                  center={mapCenter}
                  onLoad={handleMapLoad}
                  marker={watch('address.lat') && watch('address.lng') ? {
                    position: {
                      lat: parseFloat(watch('address.lat')),
                      lng: parseFloat(watch('address.lng')),
                    },
                    title: watch('address.formattedAddress') || '',
                  } : null}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientInformationForm;
