// UI
import { Button } from 'components/catalyst/button';
import { Strong, Text } from 'components/catalyst/text';
import { Box } from 'components/common/StyledBoxs';
import {
  InformationCircleIcon,
  ExclamationTriangleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline'

// Utils
import wording from 'utils/wording';
import { formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const {
  NOTIFICATION_MARK_AS_READ,
  NOTIFICATION_EMPTY,
} = wording;

const NotificationsList = ({
  notifications,
  onMarkAsRead,
  handleCloseNotificationsModal,
}) => {
  const navigate = useNavigate();

  const handleNotificationClick = (notificationId, workOrderId) => {
    handleCloseNotificationsModal();

    onMarkAsRead(notificationId, true);

    navigate(`/work-orders/${workOrderId}`)
  };

  const getIconByNotificationType = (notificationType) => {
    const typeToIcon = {
      info: <InformationCircleIcon className='size-6 stroke-blue-500' />,
      warning: <ExclamationTriangleIcon className='size-6 stroke-yellow-500' />,
      error: <ExclamationCircleIcon className='size-6 stroke-red-500' />,
    };

    if (typeof notificationType !== 'string') {
      return typeToIcon['info'];
    }

    return typeToIcon[notificationType.toLowerCase()] || typeToIcon['info'];
  };

  const getColorByNotificationType = (notificationType) => {
    const typeToColor = {
      info: '!border-blue-300 dark:!border-blue-800',
      warning: '!border-yellow-300 dark:!border-yellow-800',
      error: '!border-red-300 dark:!border-red-800',
    };

    if (typeof notificationType !== 'string') {
      return typeToColor['info'];
    }

    return typeToColor[notificationType.toLowerCase()] || typeToColor['info'];
  };

  return (
    <>
      {notifications.length === 0 ? (
        <Text>{NOTIFICATION_EMPTY}</Text>
      ) : (
        <div className='space-y-4'>
          {notifications.map(({ _id, type, relatedTo, message, read, createdAt }) => (
            <Box 
              key={_id} 
              className={`p-4 flex gap-2 ${read ? '' : getColorByNotificationType(type)} hover:cursor-pointer hover:shadow-md hover:dark:shadow-zinc-800`}
              onClick={() => handleNotificationClick(_id, relatedTo.id)}
            >
              <div>
                {getIconByNotificationType(type)}
              </div>

              <div>
                <Text>
                  <Strong>
                    {relatedTo.model}
                  </Strong>
                </Text>

                <Text>{message}</Text>

                <div className='flex flex-col md:flex-row md:justify-between md:items-center gap-4 mt-4'>
                  <Text className="">
                    {formatDistanceToNow(new Date(createdAt), { addSuffix: true })}
                  </Text>

                  {!read && (
                    <Button
                      outline
                      onClick={() => onMarkAsRead(_id)}
                      className='w-fit'
                    >
                      {NOTIFICATION_MARK_AS_READ}
                    </Button>
                  )}
                </div>
              </div>
            </Box>
          ))}
        </div>
      )}
    </>
  );
};

export default NotificationsList;
