// UI
import { Select } from "components/catalyst/select";
import { Strong, Text } from "components/catalyst/text";
import { Box } from "components/common/StyledBoxs";
import { Avatar } from 'components/catalyst/avatar';
import StatusBadge from "components/StatusBadge";
import { Heading } from "components/catalyst/heading";
import { ClockIcon } from '@heroicons/react/24/outline';
import { PhoneIcon, EnvelopeIcon } from '@heroicons/react/20/solid';

// Utils
import wording from "utils/wording";
import Loader from "components/Loader";

const WODashboardDeadlines = ({
  upcomingDeadlines,
  daysToDeadline,
  hadleChangeDaysToDeadline,
  handleNavigateToWorkOrder,
  isLoading,
  isFetchingUsers,
}) => {
  const {
    WORK_ORDER_DASH_DEADLINES,
    WORK_ORDER_DASH_NEAR_DEADLINE,
    WO_DETAIL_UNASSIGNED,
    WORK_ORDER_DASH_MORE_DEADLINE,
    GLOBAL_THIS_MONTH,
    GLOBAL_THIS_WEEK,
  } = wording;

  const daysToDealineOptions = [
    {
      label: GLOBAL_THIS_WEEK,
      value: 7,
    },
    {
      label: GLOBAL_THIS_MONTH,
      value: 30,
    },
  ];

  return (
    <div className="space-y-4 pt-4">
      <Heading className='text-xl md:text-2xl'>
        {WORK_ORDER_DASH_DEADLINES}
      </Heading>

      {(isLoading || isFetchingUsers) ? (
        <div className="w-full h-[200px] flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="flex flex-col md:flex-row md:justify-between md:items-center w-full gap-2">
            <div className="flex gap-2 items-center md:items-end">
              <Strong className='capitalize text-2xl'>
                {upcomingDeadlines.length}
              </Strong>

              <Text>
                <Strong className='capitalize'>
                  {WORK_ORDER_DASH_NEAR_DEADLINE}
                </Strong>
              </Text>
            </div>

            <div>
              <Select
                value={daysToDeadline}
                onChange={hadleChangeDaysToDeadline}
              >
                {daysToDealineOptions.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {upcomingDeadlines.slice(0, 4).map((wo) => {
              const daysLeft = Math.ceil((new Date(wo.endDate) - new Date()) / (1000 * 60 * 60 * 24));

              return (
                <Box 
                  key={wo._id}
                  onClick={() => handleNavigateToWorkOrder(wo._id)}
                  className="p-4 flex flex-col gap-2 hover:shadow hover:cursor-pointer"
                >
                  <div className="flex flex-col gap-2 md:flex-row md:items-start md:justify-between">
                    <div className="flex flex-col gap-2">
                      <Strong>
                        {wo.client?.name}
                      </Strong>

                      {wo?.client?.phone && (
                        <div className="flex gap-2">
                          <PhoneIcon className="w-4 text-zinc-500 dark:text-zinc-400"  />

                          <Text>{wo.client.phone}</Text>
                        </div>
                      )}

                      {wo?.client?.email && (
                        <div className="flex gap-2">
                          <EnvelopeIcon className="w-4 text-zinc-500 dark:text-zinc-400" />

                          <Text>{wo.client.email}</Text>
                        </div>
                      )}
                    </div>

                    <div className="flex md:justify-end items-center gap-2">
                      <Avatar className="size-10 p-2 bg-amber-50 dark:bg-amber-900/25 !outline-none">
                        <ClockIcon className="stroke-amber-600" />
                      </Avatar>

                      <Text>
                        {daysLeft} {daysLeft === 1 ? 'day' : 'days'} remaining
                      </Text>
                    </div>      
                  </div>
                  
                  <div className="flex flex-row justify-between flex-1 gap-2">
                    <div className="flex flex-wrap gap-1 ">
                      {wo.assignedTo ? (
                        <Text className='gap-1'>
                          {wo.assignedTo.firstName} {wo.assignedTo.lastName}
                        </Text>
                      ) : (
                        <Text>
                          {WO_DETAIL_UNASSIGNED}
                        </Text>
                      )}
                    </div>
                    
                    <StatusBadge status={wo.status} />
                  </div>
                </Box>
              );
            })}
          </div>      

          {upcomingDeadlines.length > 4 && (
            <Text>
              {WORK_ORDER_DASH_MORE_DEADLINE(upcomingDeadlines.length - 4)}
            </Text>
          )}
        </>
      )}
    </div>
  );
};

export default WODashboardDeadlines;
