// Module dependencies
import useWorkOrders from 'hooks/useWorkOrders';

// UI
import Breadcrumb from 'components/Breadcrumb';
import WorkOrdersSearch from 'components/workOrdersPage/WorkOrdersSearch';
import WorkOrderLocationsMap from 'components/workOrdersPage/WorkOrdersLocationsMap';
import GlobalScheduleModal from 'components/workOrdersPage/GlobalScheduleModal';
import WorkOrdersHeader from 'components/workOrdersPage/WorkOrdersHeader';
import WorkOrdersTable from 'components/workOrdersPage/WorkOrdersTable';
import Loader from 'components/Loader';

// Utils
import wording from 'utils/wording';

const WorkOrdersView = () => {
  const workOrdersHandlers = useWorkOrders();
  
  const { SIDEBAR_WORK_ORDERS, WORK_ORDERS_LIST } = wording;

  const pages = [
    { name: SIDEBAR_WORK_ORDERS, href: '/work-orders', current: false },
    { name: WORK_ORDERS_LIST, href: '/work-orders/list', current: false },
  ];

  return (
    <div className='flex flex-col gap-6'>
      <Breadcrumb pages={pages} />

      <WorkOrdersHeader {...workOrdersHandlers} />

      <>
        {workOrdersHandlers.workOrders.length === 0 && workOrdersHandlers.isLoading ? (
          <div className='flex items-center justify-center w-full h-96'>
            <Loader />
          </div>
        ) : (
          <>
            <WorkOrdersSearch {...workOrdersHandlers} />

            <WorkOrdersTable
              {...workOrdersHandlers}
              ref={workOrdersHandlers.tableRef}
            />

            <WorkOrderLocationsMap {...workOrdersHandlers} />

            <GlobalScheduleModal {...workOrdersHandlers} />
          </>
        )}
      </>

    </div>
  );
};

export default WorkOrdersView;
