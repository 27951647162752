// UI
import { Badge } from 'components/catalyst/badge';
import { Divider } from 'components/catalyst/divider';
import { Heading } from 'components/catalyst/heading';
import { Strong, Text } from 'components/catalyst/text';
import Loader from 'components/Loader';

// Utils
import wording from 'utils/wording';

const getStatusColor = (status) => {
  const mapper = {
    pending: 'sky',
    'in-progress': 'emerald',
    completed: 'green',
    cancelled: 'red',
    'on-schedule': 'blue',
  }

  return mapper[status] || 'grey';
};

const WODashboardKpis = ({
  workOrderKPIs,
  isLoading,
}) => {
  const {
    totalWorkOrders,
    workOrdersByStatus,
  } = workOrderKPIs;

  const {
    WORK_ORDER_PERCENTAGE,
    GLOBAL_PERCENTAGE,
    WORK_ORDER_DASH_OVERVIEW,
  } = wording;

  return (
    <div>
      <Heading className='text-xl md:text-2xl'>
        {WORK_ORDER_DASH_OVERVIEW}
      </Heading>

      {isLoading ? (
        <div className="w-full h-[200px] flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div className='flex flex-col gap-0 md:flex-row md:justify-between md:gap-8'>
          {Object.entries(workOrdersByStatus).map(([status, count]) => {
            return (
              <div
                key={status}
                className="py-4 flex flex-col w-full gap-2"
              >
                <Divider className='pb-4' />

                <Text>
                  <Strong className='capitalize'>
                    {status}
                  </Strong>
                </Text>

                <Strong className='capitalize text-3xl'>
                  {count}
                </Strong>

                {totalWorkOrders !== 0 && (
                  <div className='flex items-center gap-1 pt-1'>
                    <Badge color={getStatusColor(status)} className='w-min px-2'>
                      {`${((count / totalWorkOrders) * 100).toFixed(1)}${GLOBAL_PERCENTAGE}`}
                    </Badge>

                    <Text>
                      {WORK_ORDER_PERCENTAGE}
                    </Text>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>

  );
};

export default WODashboardKpis;
export {
  getStatusColor,
};
