import { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotifications, markAllAsRead, markAsRead } from "features/notifications/notificationsSlice";
import { useBasicModal } from "./useBasicModal";
import wording from "utils/wording";

const {
  NOTIFICATION_UPDATE_SUCCESS,
  NOTIFICATION_UPDATE_FAIL,
} = wording;

const useNotifications = () => {
  const dispatch = useDispatch();
  const {
    notifications,
    unreadNotifications,
    isFetchingNotifications,
    isUpdatingNotifications,
  } = useSelector((state) => state.notifications);

  const {
    showModal: showNotificationsModal,
    handleCloseModal: handleCloseNotificationsModal,
    handleOpenModal: handleOpenNotificationsModal,
  } = useBasicModal();

  useEffect(() => {
    dispatch(fetchNotifications());

    const interval = setInterval(() => {
      dispatch(fetchNotifications());
    }, 60000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const handleMarkAsRead = async (notificationId, skipToast = false) => {
    try {
      await dispatch(
        markAsRead(notificationId)
      ).unwrap();

      if (!skipToast) {
        toast.success(NOTIFICATION_UPDATE_SUCCESS);
      }
    } catch (error) {
      console.log(error);

      toast.error(NOTIFICATION_UPDATE_FAIL);
    } finally {
      dispatch(
        fetchNotifications()
      );
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await dispatch(
        markAllAsRead()
      ).unwrap();

      toast.success(NOTIFICATION_UPDATE_SUCCESS);
    } catch (error) {
      console.log(error);

      toast.error(NOTIFICATION_UPDATE_FAIL);
    } finally {
      dispatch(
        fetchNotifications()
      );
    }
  };

  return {
    notifications,
    unreadNotifications,
    isFetchingNotifications,
    isUpdatingNotifications,
    handleMarkAsRead,
    handleMarkAllAsRead,

    showNotificationsModal,
    handleCloseNotificationsModal,
    handleOpenNotificationsModal,
  };
};

export default useNotifications;
