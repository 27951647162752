
// UI
import Breadcrumb from 'components/Breadcrumb';
import Loader from 'components/Loader';
import WODetailsHeader from 'components/workOrderDetails/WODetailsHeader';
import WODetailsTabs from 'components/workOrderDetails/WODetailsTabs';
import useWorkOrderDetail from 'hooks/useWorkOrderDetail';
import wording from 'utils/wording';

const WorkOrderDetails = () => {
  const workOrderDetailHandlers = useWorkOrderDetail();

  const { workOrder, isFetchingWorkorder } = workOrderDetailHandlers;
  
  const {
    WO_DETAIL_WORK_ORDERS,
    WO_DETAIL_WORK_ORDER_NUMBER,
  } = wording;

  const pages = [
    { name: WO_DETAIL_WORK_ORDERS, href: '/work-orders/list', current: false },
    { name: `${WO_DETAIL_WORK_ORDER_NUMBER}${workOrder?._id}`, current: false },
  ];
  
  return (
    <div className='flex flex-col gap-6'>
      <Breadcrumb pages={pages} />

      <WODetailsHeader {...workOrderDetailHandlers} />

      {isFetchingWorkorder ? (
        <div className='flex items-center justify-center w-full h-96'>
          <Loader />
        </div>
      ) : (
        <WODetailsTabs workOrderDetailHandlers={workOrderDetailHandlers} />
      )}
    </div>
  );
};

export default WorkOrderDetails;
