// Module dependencies
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getWorkOrders } from 'features/workOrders/workOrderSlice';
import { getCompanyUsers } from 'features/users/userSlice';

// Utils
import { calculateWorkOrderKPIs, getUpcomingDeadlines } from 'utils/work-orders';

const useWorkOrdersDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    workOrders,
    isLoading
  } = useSelector((state) => state.workOrders);
  const { isLoading: isFetchingUsers } = useSelector((state) => state.users);

  const [daysToDeadline, setDaysToDeadline] = useState(7);
  const [upcomingDeadlines, setUpcomingDeadlines] = useState([]);
  const [workOrderKPIs, setWorkOrderKPIs] = useState({
    totalWorkOrders: 0,
    workOrdersByStatus: {
      pending: 0,
      'in-progress': 0,
      completed: 0,
      cancelled: 0,
      'on-schedule': 0,
    },
    workOrdersByPriority: {
      high: 0,
      medium: 0,
      low: 0,
      undefined: 0,
    },
    assignedWorkOrders: 0,
    unassignedWorkOrders: 0,
    assignedUsers: {}
  });

  useEffect(() => {
    dispatch(getWorkOrders());
    dispatch(getCompanyUsers());
  }, [dispatch]);

  useEffect(() => {
    if (workOrders.length > 0) {
      const stats = calculateWorkOrderKPIs(workOrders);
      setWorkOrderKPIs(stats);
    }
  }, [workOrders]);

  useEffect(() => {
    if (workOrders.length > 0) {
      const deadLines = getUpcomingDeadlines(workOrders, daysToDeadline);
      setUpcomingDeadlines(deadLines);
    }
  }, [workOrders, daysToDeadline]);

  const hadleChangeDaysToDeadline = ({ target }) => {
    setDaysToDeadline(target.value)
  };

  const handleNavigateToWorkOrder = (workOrderId) => {
    navigate(`/work-orders/${workOrderId}`);
  };
  
  return {
    workOrders,
    isLoading,
    isFetchingUsers,
  
    upcomingDeadlines,
    workOrderKPIs,

    daysToDeadline,
    hadleChangeDaysToDeadline,

    handleNavigateToWorkOrder,
  };
};

export default useWorkOrdersDashboard;
