// Modules
import { useSelector } from 'react-redux';

// UI
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from 'components/catalyst/navbar';
import { Badge } from 'components/catalyst/badge';
import UserMenu from './UserMenu';
import { MoonIcon, SunIcon, BellIcon } from '@heroicons/react/20/solid';

const SidebarNavbar = ({
  isDarkMode,
  toggleDarkMode,
  handleOpenNotificationsModal,
}) => {
  const { unreadNotifications } = useSelector(state => state.notifications);

  return (
    <Navbar>
      <NavbarSpacer />
      <NavbarSection>
        <NavbarItem onClick={handleOpenNotificationsModal} className='relative'>
          <BellIcon />

          {unreadNotifications.length > 0 && (
            <Badge color='blue' className='absolute bottom-5 left-6'>
              {unreadNotifications.length}
            </Badge>
          )}
        </NavbarItem>

        <NavbarItem onClick={toggleDarkMode}>
          {isDarkMode ? <SunIcon /> : <MoonIcon />}
        </NavbarItem>

        <UserMenu />
      </NavbarSection>
    </Navbar>
  );
};

export default SidebarNavbar;
