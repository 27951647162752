// Modules
import useCompanyDocuments from 'hooks/useCompanyDocuments';

// UI
import Breadcrumb from 'components/Breadcrumb';
import { Text } from 'components/catalyst/text';
import { Button } from 'components/catalyst/button';
import { Heading } from 'components/catalyst/heading';
import { PlusIcon } from '@heroicons/react/24/outline';
import UploadDocumentDialog from 'components/documents/UploadDocumentDialog';
import DocumentCard from 'components/documents/DocumentCard';

// Utils
import wording from 'utils/wording';

const {
  SIDEBAR_DOCUMENTS,
  DOCUMENTS_COMPANY_TITLE,
  DOCUMENTS_UPLOAD,
  DOCUMENTS_EMPTY_STATE,
 } = wording;

const DocumentsView = () => {
  const companyDocumentsHandlers = useCompanyDocuments();

  const {
    documents,
    handleOpenUploadModal,
  } = companyDocumentsHandlers;

  const pages = [
    { name: SIDEBAR_DOCUMENTS, href: '/documents', current: false },
  ];

  return (
    <div className='flex flex-col gap-6'>
      <Breadcrumb pages={pages} />

      <div className="flex flex-row justify-between items-center sm:items-center gap-4">
        <Heading className='text-xl md:text-2xl'>{DOCUMENTS_COMPANY_TITLE}</Heading>
      
        <Button
          color='blue'
          onClick={handleOpenUploadModal}
        >
          <PlusIcon />
          <span className='hidden md:block'>
            {DOCUMENTS_UPLOAD}
          </span>

          <span className='md:hidden block'>
            {DOCUMENTS_UPLOAD.slice(0, 6)}
          </span>
        </Button>
      </div>
      
      {documents.length === 0 ? (
        <Text>
          {DOCUMENTS_EMPTY_STATE}
        </Text>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {documents.map((document) => (
            <DocumentCard
              key={document._id}
              document={document}
              {...companyDocumentsHandlers}
            />
          ))}
        </div>
      )}

      <UploadDocumentDialog {...companyDocumentsHandlers} />
    </div>
  );
}

export default DocumentsView;
