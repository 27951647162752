const featureConfig = {
  projects: process.env.REACT_APP_FEATURE_PROJECTS === 'true',
  dashboard: process.env.REACT_APP_FEATURE_DASHBOARD === 'true',
  workOrders: process.env.REACT_APP_FEATURE_WORK_ORDERS === 'true',
  team: process.env.REACT_APP_FEATURE_TEAM === 'true',
  schedule: process.env.REACT_APP_FEATURE_SCHEDULE === 'true',
  documents: process.env.REACT_APP_FEATURE_DOCUMENTS === 'true',
  companies: process.env.REACT_APP_FEATURE_COMPANIES === 'true',
  notifications: true,
};

export {
  featureConfig,
};
