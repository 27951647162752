// UI
import { Strong, Text } from 'components/catalyst/text';
import packageJson from '../../../package.json';

// Utils
import wording from 'utils/wording';

const Copyright = () => {
  const { COPYRIGHT_TEXT, SIDEBAR_VERSION } = wording;

  const currentYear = new Date().getFullYear();

  return (
    <div className="mt-8">
      <Text className='text-center'>
        {COPYRIGHT_TEXT(currentYear)}
      </Text>

      <Text className='text-center pt-1'>
        {SIDEBAR_VERSION.slice(9)}

        <Strong className='pl-1'>{`${packageJson.version}`}</Strong>
      </Text>
    </div>
  );
};

export default Copyright;
