import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import notificationsService from './notificationsService';
import { getErrorMessage } from 'utils/commons';

const initialState = {
  notifications: [],
  unreadNotifications: [],
  isFetchingNotifications: false,
  isUpdatingNotifications: false,
  error: null,
};

export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async (_, { rejectWithValue }) => {
    try {
      return await notificationsService.getNotifications();
    } catch (error) {
      const message = getErrorMessage(error);

      return rejectWithValue(message);
    }
  }
);

export const markAsRead = createAsyncThunk(
  'notifications/markAsRead',
  async (notificationId, { rejectWithValue }) => {
    try {
      return notificationsService.markNotificationAsRead(notificationId);
    } catch (error) {
      const message = getErrorMessage(error);

      return rejectWithValue(message);
    }
  }
);

export const markAllAsRead = createAsyncThunk(
  'notifications/markAllAsRead',
  async (_, { rejectWithValue }) => {
    try {
      return await notificationsService.markAllNotificationsAsRead();
    } catch (error) {
      const message = getErrorMessage(error);

      return rejectWithValue(message);
    }
  }
);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.isFetchingNotifications = true;
        state.error = null;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload;
        state.unreadNotifications = action.payload.filter(n => !n.read);
        state.isFetchingNotifications = false;
        state.error = null;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.isFetchingNotifications = false;
        state.error = action.payload;
        state.notifications = [];
      })
      .addCase(markAsRead.pending, (state) => {
        state.isUpdatingNotifications = true;
      })
      .addCase(markAsRead.fulfilled, (state) => {
        state.isUpdatingNotifications = false;

      })
      .addCase(markAsRead.rejected, (state) => {
        state.isUpdatingNotifications = false;
      })
      .addCase(markAllAsRead.pending, (state) => {
        state.isUpdatingNotifications = true;
      })
      .addCase(markAllAsRead.fulfilled, (state) => {
        state.isUpdatingNotifications = false;
      })
      .addCase(markAllAsRead.rejected, (state) => {
        state.isUpdatingNotifications = false;
      });
  },
});

// Selectors
export const selectNotifications = state => state.notifications?.notifications || [];
export const selectUnreadCount = state => 
  state.notifications?.notifications?.filter(n => !n.read)?.length || 0;

export default notificationsSlice.reducer; 