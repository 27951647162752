// UI
import StatusCard from './StatusCard';
import AssignmentCard from './AssignmetCard';
import WorkDetailsCard from './WorkDetailsCard';
import DinamicCard from './DinamicCard';
import Loader from 'components/Loader';

// Utils
import { templateKeys } from 'utils/form-utils';

const WODetailsOverviewTab = ({
  workOrderDetailHandlers,
  isFetchingWorkorder,
}) => {
  const { workOrdersTemplate } = workOrderDetailHandlers;
  
  return (
    <>
      {isFetchingWorkorder ? (
        <div className='flex items-center justify-center w-full h-96'>
          <Loader />
        </div>
      ) : (
        <div className="columns-1 md:columns-2 gap-4 space-y-4">
          <StatusCard {...workOrderDetailHandlers} />

          <AssignmentCard {...workOrderDetailHandlers} />

          <WorkDetailsCard {...workOrderDetailHandlers} />

          {workOrdersTemplate && templateKeys.map(
            (key) =>
              workOrdersTemplate[key]?.length > 0 && (
                <DinamicCard
                  key={key}
                  {...workOrderDetailHandlers}
                  templateKey={key}
                />
              )
          )}
        </div>
      )}
    </>
  );
};

export default WODetailsOverviewTab;
