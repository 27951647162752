// Module dependencies
import { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { featureConfig } from 'config/features';
import packageJson from '../../../package.json';
import { BuildingOfficeIcon } from '@heroicons/react/20/solid';

// UI
import UserMenu from './UserMenu';
import {
  Sidebar,
  SidebarBody,
  SidebarDivider,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from 'components/catalyst/sidebar'
import {
  HomeIcon,
  ClipboardDocumentListIcon,
  FolderIcon,
  // CalendarDaysIcon,
  UsersIcon,
  CalendarIcon,
  DocumentIcon,
  MoonIcon,
  SunIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  MapPinIcon,
  NewspaperIcon,
  BellIcon,
  BuildingOffice2Icon,
} from '@heroicons/react/20/solid';
import { Badge } from 'components/catalyst/badge';
import NimaxLogo from 'components/NimaxLogo';
import { Strong, Text } from 'components/catalyst/text';

// Utils
import wording from 'utils/wording';

const SidebarMain = ({
  isDarkMode,
  toggleDarkMode,
  showNotificationsModal,
  handleOpenNotificationsModal,
}) => {
  const location = useLocation();

  const auth = useSelector((state) => state.auth);
  const userRole = auth?.user?.user?.role;

  const { unreadNotifications } = useSelector((state) => state.notifications);

  // TODO: For now always open, Reactivate when we have more features
  // const workOrdersPaths = [
  //   "/work-orders",
  //   "/work-orders/pending-locations",
  //   "/work-orders/global-schedule",
  // ];

  // const isWorkOrdersPath = workOrdersPaths.includes(location.pathname);

  const [panelState, setPanelState] = useState({
    'work-orders': true,
  });

  // const togglePanelState = (id) => {
  //   setPanelState((prevState) => ({
  //     ...prevState,
  //     [id]: !prevState[id],
  //   }));
  // };

  const {
    SIDEBAR_DASHBOARD,
    SIDEBAR_PROJECTS,
    SIDEBAR_SCHEDULING,
    SIDEBAR_WORK_ORDERS,
    SIDEBAR_WORK_ORDERS_LIST,
    SIDEBAR_TEAM,
    SIDEBAR_DOCUMENTS,
    SIDEBAR_THEME,
    SIDEBAR_VERSION,
    SIDEBAR_NOTIFICATIONS,
    SIDEBAR_PENDING_LOCATIONS,
    SIDEBAR_GLOBAL_SCHEDULE,
    SIDEBAR_TEMPLATES,
    // GLOBAL_NEW,
    // GLOBAL_SOON,
  } = wording;

  const primaryNavigationList = [
    {
      id: "dashboard",
      name: SIDEBAR_DASHBOARD,
      href: "/dashboard",
      icon: <HomeIcon />,
      enabled: featureConfig.dashboard,
    },
    {
      id: "companies",
      name: "Companies",
      href: "/companies",
      icon: <BuildingOfficeIcon />,
      enabled: featureConfig.companies && userRole === 'SUPERADMIN',
    },
    {
      name: SIDEBAR_PROJECTS,
      href: "/projects",
      icon: <FolderIcon />,
      enabled: featureConfig.projects,
    },
    {
      name: SIDEBAR_SCHEDULING,
      href: "/schedule",
      icon: <CalendarIcon />,
      enabled: featureConfig.schedule,
    },
    {
      id: "work-orders",
      name: SIDEBAR_WORK_ORDERS,
      href: "/work-orders",
      icon: panelState["work-orders"] ? (
        <ChevronDownIcon />
      ) : (
        <ChevronRightIcon />
      ),
      enabled: featureConfig.workOrders,
      children: [
        {
          name: SIDEBAR_WORK_ORDERS_LIST,
          href: "/work-orders/list",
          icon: <ClipboardDocumentListIcon />,
        },
        {
          name: SIDEBAR_PENDING_LOCATIONS,
          href: "/work-orders/pending-locations",
          icon: <MapPinIcon />,
        },
        {
          name: SIDEBAR_GLOBAL_SCHEDULE,
          href: "/work-orders/global-schedule",
          icon: <CalendarIcon />,
        },
        {
          name: SIDEBAR_TEMPLATES,
          href: "/work-orders/templates",
          icon: <NewspaperIcon />,
        },
      ],
    },
  ];

  const secondaryNavigationList = [
    {
      name: SIDEBAR_TEAM,
      href: "/team",
      icon: <UsersIcon />,
      enabled: featureConfig.team,
    },
    {
      name: SIDEBAR_DOCUMENTS,
      href: "/documents",
      icon: <DocumentIcon />,
      enabled: featureConfig.documents,
    },
  ];

  return (
    <>
      <Sidebar>
        <SidebarHeader>
          <SidebarSection>
            <div className="flex items-center gap-2 w-full py-3 pl-2">
              <NimaxLogo size='small' />
            </div>
          </SidebarSection>

          <SidebarSection>
            {primaryNavigationList.map(({ id, name, href, icon, enabled, children }) => (
              <Fragment key={name}>
                {enabled && (
                  <>
                    <NavLink to={href}>
                      <SidebarItem
                        current={location.pathname === href}
                        className={enabled ? 'cursor-pointer' : '!cursor-not-allowed'}
                        disabled={!enabled}
                      >
                        {icon}
                        <SidebarLabel>{name}</SidebarLabel>
                        
                        {/* <Badge color={enabled ? 'green' : 'zinc'} className='capitalize'>
                          {enabled ? GLOBAL_NEW : GLOBAL_SOON }
                        </Badge> */}
                      </SidebarItem>
                    </NavLink>

                    {children && panelState[id] && (
                      <>
                        {children.map(({ name, href, icon }) => (
                          <NavLink to={href} key={`${name}-${href}`}>
                            <SidebarItem
                              current={location.pathname === href}
                            >
                              {icon}
                              <SidebarLabel>{name}</SidebarLabel>
                            </SidebarItem>
                          </NavLink>
                        ))}
                      </>
                    )}
                  </>
                )}
              </Fragment>
            ))}
          </SidebarSection>


        </SidebarHeader>

        <SidebarBody>
          <SidebarSection>
            {secondaryNavigationList.map(({ id, name, href, icon, enabled, children }) => (
              <Fragment key={name}>
                {enabled && (
                  <>
                    <NavLink to={href}>
                      <SidebarItem
                        current={location.pathname === href}
                        className={`max-h-[20px] ${enabled ? 'cursor-pointer' : '!cursor-not-allowed'}`}
                        disabled={!enabled}
                      >
                        {icon}
                        <SidebarLabel>{name}</SidebarLabel>

                        {name === SIDEBAR_NOTIFICATIONS && unreadNotifications.length > 0 && (
                          <Badge color={unreadNotifications.length > 0 ? 'blue' : 'zinc'} className='h-5 w-5'>
                            {unreadNotifications.length}
                          </Badge>
                        )}
                      </SidebarItem>
                    </NavLink>

                    {children && panelState[id] && (
                      <>
                        {children.map(({ name, href, icon }) => (
                          <NavLink to={href} key={`${name}-${href}`}>
                            <SidebarItem
                              current={location.pathname === href}
                            >
                              {icon}
                              <SidebarLabel>{name}</SidebarLabel>
                            </SidebarItem>
                          </NavLink>
                        ))}
                      </>
                    )}
                  </>
                )}
              </Fragment>
            ))}
            
            <SidebarItem
              current={showNotificationsModal}
              className={featureConfig.notifications ? 'cursor-pointer' : '!cursor-not-allowed'}
              disabled={!featureConfig.notifications}
              onClick={handleOpenNotificationsModal}
            >
              <BellIcon />
              <SidebarLabel>{SIDEBAR_NOTIFICATIONS}</SidebarLabel>

              {unreadNotifications.length > 0 && (
                <Badge color={unreadNotifications.length > 0 ? 'blue' : 'zinc'} className='h-5 w-5'>
                  {unreadNotifications.length}
                </Badge>
              )}
            </SidebarItem>
          </SidebarSection>

          <SidebarSpacer />

          <SidebarSection>
            <SidebarItem onClick={toggleDarkMode}>
              {isDarkMode ? <SunIcon /> : <MoonIcon />}
              <SidebarLabel>{SIDEBAR_THEME}</SidebarLabel>
            </SidebarItem>
          </SidebarSection>

          <Text className='px-3 pt-2'>
            {SIDEBAR_VERSION}
            <Strong className='pl-1'>{`${packageJson.version}`}</Strong>
          </Text>

          {auth?.user?.user?.role !== 'SUPERADMIN' && (
            <>
              <SidebarDivider />    

              <div className='px-3 flex gap-3 items-center'>
                <BuildingOffice2Icon className='h-5 fill-zinc-500 dark:fill-zinc-500' />

                <Text className='!leading-4'>
                  <Strong className='!leading-4'>
                    {auth?.user?.user?.companyData?.name}
                  </Strong>
                </Text>
              </div>
            </>
          )}
        </SidebarBody>

        <SidebarFooter className="max-lg:hidden">
          <UserMenu type='sidebar' />
        </SidebarFooter>
      </Sidebar>
    </>
  );
};

export default SidebarMain;
