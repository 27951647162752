// UI
import { Badge } from 'components/catalyst/badge';
import { Checkbox, CheckboxField } from 'components/catalyst/checkbox';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from 'components/catalyst/description-list';
import { Heading } from 'components/catalyst/heading';
import { Input } from 'components/catalyst/input';
// import { Select } from 'components/catalyst/select';
import { Textarea } from 'components/catalyst/textarea';
import Card from 'components/common/Card';
import TagInput from 'components/workOrder/TagInput';
import { Controller } from 'react-hook-form';

// Utils
import { formatCurrency } from 'utils/commons';
import wording from 'utils/wording';

const WorkDetailsCard = ({
  workOrder,
  isEditing,
  register,
  control,
}) => {
  const {
    WO_DETAIL_WORK_DETAILS,
    WO_DETAIL_WORK_PERFORMED,
    WO_DETAIL_AMOUNT_DUE,
    CREATE_WO_CASH_ON_DELIVERY,
    WO_DETAIL_UNDER_WARRANTY,
    GLOBAL_YES,
    GLOBAL_NO,
    WORK_ORDERS_TAGS,
    // WO_DETAIL_TECHNICIAN,
    // GLOBAL_UNASSIGNED,
  } = wording;
  
  return (
    <Card className='break-inside-avoid h-min'>
      <Heading level={2} className='pb-2 text-xl md:text-2xl'>
        {WO_DETAIL_WORK_DETAILS}
      </Heading>

      <DescriptionList>
        <DescriptionTerm>
          {WO_DETAIL_AMOUNT_DUE}
        </DescriptionTerm>
        
        <DescriptionDetails>
          {isEditing ? (
            <Input
              type="number"
              {...register('amountDue')}
            />
          ) : (
            <>
              {formatCurrency(workOrder?.amountDue)}
            </>
          )}
        </DescriptionDetails>

        <DescriptionTerm>
          {WO_DETAIL_WORK_PERFORMED}
        </DescriptionTerm>
        
        <DescriptionDetails>
          {isEditing ? (
            <Textarea 
              name="workPerformed"
              {...register('workPerformed')}
              rows={4}
            />
          ) : (
            <>
              {workOrder?.workPerformed}
            </>
          )}
        </DescriptionDetails>

        <DescriptionTerm>
          {CREATE_WO_CASH_ON_DELIVERY}
        </DescriptionTerm>

        <DescriptionDetails className='flex items-center'>
          {isEditing ? (
            <Controller
              name="cashOnDelivery"
              control={control}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onChange={(checked) => field.onChange(checked)}
                />
              )}
            />
          ) : (
            <>
              {workOrder?.cashOnDelivery ? GLOBAL_YES : GLOBAL_NO}
            </>
          )}
        </DescriptionDetails>

        <DescriptionTerm>
          {WO_DETAIL_UNDER_WARRANTY}
        </DescriptionTerm>

        <DescriptionDetails className='flex items-center'>
          {isEditing ? (
            <Controller
              name="underWarranty"
              control={control}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onChange={(checked) => field.onChange(checked)}
                />
              )}
            />
          ) : (
            <>
              {workOrder?.underWarranty ? GLOBAL_YES : GLOBAL_NO}
            </>
          )}
        </DescriptionDetails>

        <DescriptionTerm>
          {WORK_ORDERS_TAGS}
        </DescriptionTerm>

        <DescriptionDetails className='flex items-center'>
          {isEditing ? (
            <Controller
              name="tags"
              control={control}
              render={({ field }) => (
                <TagInput
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          ) : (
            <div className="flex flex-wrap gap-1 max-h-[54px] md:overflow-auto">
              {workOrder?.tags?.map((tag, index) => (
                <Badge
                  key={index}
                  color='blue'
                >
                  {tag}
                </Badge>
              ))}
            </div>
          )}
        </DescriptionDetails>
      </DescriptionList>
    </Card>
  );
};

export default WorkDetailsCard;
