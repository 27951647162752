// UI
import { Field, Label } from "components/catalyst/fieldset";
import { Input } from "components/catalyst/input";
import { Strong, Text } from "components/catalyst/text";

// Utils
import wording from "utils/wording";

const DocumentsForm = ({
  handleFileChange,
  filesToUpload,
}) => {
  const {
    GLOBAL_DASH,
  } = wording;
  
  return (
    <div className="space-y-4">
      <Strong>
        {'Associated Documents'}
      </Strong>

      <Field>
        <Label>
          {'Documents'}
        </Label>

        <Input
          type="file"
          multiple
          onChange={handleFileChange}
        />
      </Field>

      {filesToUpload.map(({ lastModified, name }) => (
        <Text key={lastModified}>
          {`${GLOBAL_DASH} ${name}`}
        </Text>
      ))}
    </div>
  );
};

export default DocumentsForm;
