// Module dependencies
import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMaps } from 'contexts/MapsContext';
import { getWorkOrder, updateWorkOrder, deleteWorkOrder, getWorkOrdersTemplate } from 'features/workOrders/workOrderSlice';
import { getCompanyUsers } from 'features/users/userSlice';
import { fetchProjects } from 'features/projects/projectSlice';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import workOrderService from 'features/workOrders/workOrderService';
import useCalendar from './useCalendar';
import { useBasicModal } from './useBasicModal';

// utils
import wording from 'utils/wording';
import { formatDateForInput } from 'utils/commons';

const {
  DOCUMENTS_DOWNLOADING,
  DOCUMENTS_DOWNLOAD_FAIL,
  DOCUMENTS_UPLOAD_FAIL,
  DOCUMENTS_UPLOAD_SUCCESS,
  DOCUMENTS_DELETE_FAIL,
  DOCUMENTS_DELETE_SUCCESS,
  WO_UPDATE_SUCCESS,
  WO_UPDATE_FAIL,
  WO_DELETE_SUCCESS,
  WO_DELETE_FAIL,
} = wording;

const INITIAL_KEYS = [
  'status',
  'startDate',
  'endDate',
  'assignedTo',
  'amountDue',
  'workPerformed',
  'cashOnDelivery',
  'underWarranty',
  'inspectionPriority',
  'templateData',
  'client',
  'address',
  'tags',
];

const useWorkOrderDetail = () => {
  const { id } = useParams();
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoaded } = useMaps();

  const {
    calendarRef,
    scheduleView,
    currentDate,
    handlePrevious,
    handleNext,
    handleViewChange,
  } = useCalendar();

  const {
    workOrder,
    workOrders,
    isFetchingWorkorder,
    isLoading,
    isDeletingWorkOrder,
    workOrdersTemplate,
    isFetchingTemplate,
  } = useSelector((state) => state.workOrders);
  const { projects = [] } = useSelector((state) => state.projects);
  const { users = [], technicians } = useSelector((state) => state.users);

  const { register, handleSubmit, setValue, watch, control, reset } = useForm({
    mode: 'onBlur',
    defaultValues: {},
  });

  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');

  const [isDeleting, setIsDeleting] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState('');

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [fileDescription, setFileDescription] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  // Map States
  const [autocomplete, setAutocomplete] = useState(null);
  const [map, setMap] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 25.7617, lng: -80.1918 });

  const {
    showModal: showUploadDialog,
    handleOpenModal: handleOpenUploadModal,
    handleCloseModal: handleCloseUploadModal,
  } = useBasicModal();

  const {
    showModal: showDeleteDocumentModal,
    handleOpenModal: handleOpenDeleteDocumentModal,
    handleCloseModal: handleCloseDeleteDocumentModal,
  } = useBasicModal();

  const {
    showModal: showDeleteWOModal,
    handleOpenModal: handleOpenDeleteWOModal,
    handleCloseModal: handleCloseDeleteWOModal,
  } = useBasicModal();

  useEffect(() => {
    if (id) {
      dispatch(getCompanyUsers());
      dispatch(fetchProjects());
      dispatch(getWorkOrder(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (workOrder && workOrder.template) {
      dispatch(getWorkOrdersTemplate(workOrder.template));
    }

    if (workOrder) {
      INITIAL_KEYS.forEach((key) => {
        let value = workOrder[key];
  
        if (key === 'startDate' || key === 'endDate') {
          value = formatDateForInput(value);
        }

        if (key === 'assignedTo') {
          value = workOrder[key]?._id || undefined;
        }
  
        setValue(key, value);
      });
    }
  }, [workOrder, dispatch, setValue]);

  useEffect(() => {
    if (workOrder?.address?.lat && workOrder?.address?.lng) {
      setMapCenter({
        lat: parseFloat(workOrder.address.lat),
        lng: parseFloat(workOrder.address.lng)
      });
    }
  }, [workOrder]);

  const resetForm = () => {
    reset();

    if (workOrder) {
      INITIAL_KEYS.forEach((key) => {
        let value = workOrder[key];
  
        if (key === 'startDate' || key === 'endDate') {
          value = formatDateForInput(value);
        }
  
        setValue(key, value);
      });
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleBackToWorkOrder = () => {
    reset();

    navigate('/work-orders/list');
  };

  const handleCancel = () => {
    resetForm();
  
    setIsEditing(false);
  };

  const onSubmit = async (data) => {
    try {
      await dispatch(updateWorkOrder({ 
        workOrderId: id, 
        workOrderData: {
          ...data,
          assignedTo: data.assignedTo === "" ? undefined : data.assignedTo
        } 
      })).unwrap();
      
      setIsEditing(false);

      await dispatch(getWorkOrder(id));

      toast.success(WO_UPDATE_SUCCESS);
    } catch (error) {
      toast.error(WO_UPDATE_FAIL);
      console.error('Update error:', error);
    }
  };

  const handleDeleteWorkOrder = async (workOrderID) => {
    try {
      handleCloseDeleteWOModal();
      
      await dispatch(
        deleteWorkOrder(workOrderID)
      );

      handleBackToWorkOrder();

      toast.success(WO_DELETE_SUCCESS);
    } catch (error) {
      toast.error(WO_DELETE_FAIL);

      console.error('Update error:', error);
    }
  };

  const getWorkOrderMarkers = () => {
    return workOrders
      .filter(wo => 
        wo._id !== id && // Exclude current work order
        wo.address?.lat && 
        wo.address?.lng
      )
      .map(wo => ({
        position: {
          lat: parseFloat(wo.address.lat),
          lng: parseFloat(wo.address.lng)
        },
        title: `${wo.client?.name} - ${wo.address?.formattedAddress}`,
        projectId: wo._id // We'll use this for navigation
      }));
  };

  const handleMarkerClick = (workOrderId) => {
    navigate(`/work-orders/${workOrderId}`);
  };

  const handleDownloadDocument = async (documentId) => {
    toast.success(DOCUMENTS_DOWNLOADING);
  
    try {
      const { url } = await workOrderService.getDocumentUrl(workOrder._id, documentId);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'fileName';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error(DOCUMENTS_DOWNLOAD_FAIL);
      console.error('Download error:', error);
    }
  };

  const handleDeleteButtonClick = (document) => {
    setDocumentToDelete(document);

    handleOpenDeleteDocumentModal();
  };

  const handleDeleteDocument = async () => {
    setIsDeleting(true);

    try {
      await workOrderService.deleteDocument(workOrder._id, documentToDelete._id);

      await dispatch(getWorkOrder(workOrder._id));

      toast.success(DOCUMENTS_DELETE_SUCCESS);

      handleCloseDeleteDocumentModal();
    } catch (error) {
      toast.error(DOCUMENTS_DELETE_FAIL);
  
      console.error('Delete error:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleUploadDocument = async (e) => {
    e.preventDefault();
    setIsUploading(true);

    try {
      for (const file of filesToUpload) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('description', fileDescription);

        await workOrderService.uploadDocument(workOrder._id, formData);
      }
      
      await dispatch(getWorkOrder(workOrder._id));

      toast.success(DOCUMENTS_UPLOAD_SUCCESS);
  
      setFileDescription('');
      setFilesToUpload(null)
      handleCloseUploadModal(false);
    } catch (error) {
      toast.error(DOCUMENTS_UPLOAD_FAIL);
      console.error('Upload error:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileChange = (event) => {
    setFilesToUpload([...event.target.files]);
  };

  const handleFileDescriptionChange = ({ target}) => {
    setFileDescription(target.value);
  };

  // Map handlers
  const onLoad = useCallback((autocomplete) => {
    setAutocomplete(autocomplete);
  }, []);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const location = {
          formattedAddress: place.formatted_address,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          placeId: place.place_id,
        };
        
        setMapCenter({
          lat: location.lat,
          lng: location.lng
        });
        
        setValue('address.formattedAddress', location.formattedAddress);
        setValue('address.lat', location.lat);
        setValue('address.lng', location.lng);
        setValue('address.placeId', location.placeId);
        
        if (map) {
          map.panTo({ lat: location.lat, lng: location.lng });
          map.setZoom(15);
        }
      }
    }
  };

  const handleMapLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  return {
    // Global
    workOrder,
    workOrdersTemplate,
    isFetchingTemplate,

    // States
    isFetchingWorkorder,
    isLoading,
    isEditing,
    projects,
    users,
    technicians,
    activeTab,
    setActiveTab,
    isDeleting,

    // react-hook-form
    register,
    control,
    handleSubmit,
    onSubmit,
    watch,
  
    // Calendar
    calendarRef,
    scheduleView,
    currentDate,
    handlePrevious,
    handleNext,
    handleViewChange,

    // Map
    isLoaded,
    mapCenter,
    onLoad,
    onPlaceChanged,
    handleMapLoad,

    // Documents
    showUploadDialog,
    handleOpenUploadModal,
    handleCloseUploadModal,
    showDeleteDocumentModal,
    handleOpenDeleteDocumentModal,
    handleCloseDeleteDocumentModal,
    handleDownloadDocument,
    handleDeleteButtonClick,
    handleDeleteDocument,
    fileDescription,
    isUploading,
    handleFileChange,
    handleUploadDocument,
    handleFileDescriptionChange,
    
    handleEdit,
    handleCancel,
    getWorkOrderMarkers,
    handleMarkerClick,
    handleBackToWorkOrder,

    // Delete
    isDeletingWorkOrder,
    showDeleteWOModal,
    handleOpenDeleteWOModal,
    handleCloseDeleteWOModal,
    handleDeleteWorkOrder,
    documentToDelete,
  };
};

export default useWorkOrderDetail;
