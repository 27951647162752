// UI
import { Strong } from "components/catalyst/text";
import Card from "components/common/Card";

// Utils
import { renderFormFields } from "utils/form-utils";
import wording from "utils/wording";

const DinamicCreateForm = ({
  register,
  control,
  errors,
  selectedTemplate,
  templateKey,
}) => {
  const {
    CREATE_WO_DINAMIC_SERVICE,
    CREATE_WO_DINAMIC_MATERIALS,
    CREATE_WO_DINAMIC_DETAILS,
  } = wording;

  const getCardTitle = () => {
    const keyToTitleMapper ={
      service: CREATE_WO_DINAMIC_SERVICE,
      materials: CREATE_WO_DINAMIC_MATERIALS,
      details: CREATE_WO_DINAMIC_DETAILS,
    }

    return keyToTitleMapper[templateKey] || keyToTitleMapper.details;
  };

  return (
    <Card>
      <div className="space-y-4">
        <Strong>
          {getCardTitle()}
        </Strong>

        {renderFormFields(selectedTemplate[templateKey], templateKey, register, control, errors)}
      </div>
    </Card>
  );
};

export default DinamicCreateForm;
