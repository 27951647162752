// Module dependencies
import { useMaps } from "contexts/MapsContext";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form'
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import { createWorkOrder, getWorkOrdersTemplates } from "features/workOrders/workOrderSlice";
import { getCompanyUsers } from "features/users/userSlice";
import workOrderService from "features/workOrders/workOrderService";

// Utils
import wording from "utils/wording";

const INITIAL_VALUES = {
  client: {
    name: '',
    email: '',
    phone: '',
  },
  address: {
    formattedAddress: '',
    lat: null,
    lng: null,
    placeId: '',
  },
  dateRequested: new Date().toISOString().slice(0, 16),
  inspectionPriority: '',
  assignedTo: '',
  underWarranty: false,
  tags: [],
  workPerformed: '',
  amountDue: 0,
  cashOnDelivery: false,
}

const {
  WO_CREATE_SUCCESS,
  WO_CREATE_FAIL,
} = wording;

const useCreateWorkOrderForm = () => {
  const { technicians, users } = useSelector(state => state.users);
  const { isLoading, workOrdersTemplates } = useSelector(state => state.workOrders);

  const { register, handleSubmit, formState: { errors, isValid, isDirty }, getValues, setValue, watch, control, reset } = useForm({
    mode: 'onBlur',
    defaultValues: {},
  });
  
  const { isLoaded } = useMaps();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const [assignedUsers, setAssignedUsers] = useState([]);

  // Map States
  const [autocomplete, setAutocomplete] = useState(null);
  const [map, setMap] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 25.7617, lng: -80.1918 });

  useEffect(() => {
    dispatch(getCompanyUsers());

    dispatch(getWorkOrdersTemplates());
  }, [dispatch]);

  useEffect(() => {
    setSelectedTemplate(workOrdersTemplates[0])
  }, [workOrdersTemplates])
  
  useEffect(() => {
    Object.entries(INITIAL_VALUES).forEach(([key, value]) => {
      setValue(key, value);
    });
  }, [setValue]);

  const onSubmit = async (data) => {
    try {
      setIsUploading(true);

      const { data: createdWorkOrder } = await dispatch(createWorkOrder({
        ...data,
        templateId: selectedTemplate._id,
        status: 'pending',
      })).unwrap();

      if (filesToUpload.length > 0) {
        await uploadFiles(createdWorkOrder._id);
      }

      toast.success(WO_CREATE_SUCCESS);

      navigate('/work-orders');
    } catch (error) {
      console.error(WO_CREATE_FAIL, error);

      toast.error(WO_CREATE_FAIL);
    } finally {
      setIsUploading(false);
    }
  }

  const uploadFiles = async (workOrderId) => {
    try {
      for (const file of filesToUpload) {
        const formData = new FormData();
        formData.append('file', file);

        await workOrderService.uploadDocument(workOrderId, formData);
      }
    } catch (error) {
      console.error("File upload failed:", error);
    }
  };

  const handleFileChange = (event) => {
    setFilesToUpload([...event.target.files]);
  };

  // Map handlers
  const onLoad = useCallback((autocomplete) => {
    setAutocomplete(autocomplete);
  }, []);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const location = {
          formattedAddress: place.formatted_address,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          placeId: place.place_id,
        };
        
        setMapCenter({
          lat: location.lat,
          lng: location.lng
        });
        
        setValue('address.formattedAddress', location.formattedAddress);
        setValue('address.lat', location.lat);
        setValue('address.lng', location.lng);
        setValue('address.placeId', location.placeId);
        
        if (map) {
          map.panTo({ lat: location.lat, lng: location.lng });
          map.setZoom(15);
        }
      }
    }
  };

  const handleMapLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  const handleCancelCreate = () => {
    navigate('/work-orders');
  };

  const handleCreateTemplate = () => {
    navigate('/work-orders/templates');
  };

  const handleTemplateChange = ({ target }) => {
    const selectedTemplate = workOrdersTemplates.find(
      ({ _id }) => _id === target.value
    )
    
    if (selectedTemplate) {
      setSelectedTemplate(selectedTemplate);

      const currentValues = getValues();

      reset({
        ...currentValues,
        template: selectedTemplate._id,
        templateData: selectedTemplate.templateData || {}, // Reset con valores del nuevo template
      });
    }
  };

  return {
    register,
    handleSubmit,
    onSubmit,
    errors,
    isValid,
    isDirty,
    getValues,
    watch,
    control,
    isLoading,
    handleTemplateChange,
    selectedTemplate,
    workOrdersTemplates,

    // Map
    isLoaded,
    mapCenter,
    onLoad,
    onPlaceChanged,
    handleMapLoad,

    handleCancelCreate,
    users,
    technicians,
    handleCreateTemplate,

    handleFileChange,
    isUploading,
    filesToUpload,

    assignedUsers,
    setAssignedUsers,
  }
}

export default useCreateWorkOrderForm;
