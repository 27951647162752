import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useBasicModal } from "./useBasicModal";
import { createWorkOrdersTemplate, deleteWorkOrdersTemplate, getWorkOrdersTemplates } from "features/workOrders/workOrderSlice";
import toast from "react-hot-toast";
import wording from "utils/wording";
import { useNavigate } from "react-router-dom";

const {
  CREATE_WO_TEMPLATE_SUCCESS,
  CREATE_WO_TEMPLATE_FAIL,
  DELETE_WO_TEMPLATE_SUCCESS,
  DELETE_WO_TEMPLATE_FAIL,
} = wording;

const EMPTY_TEMPLATE = {
  company: { _id: '', name: '' },
  createdAt: '',
  createdBy: { _id: '', email: '', firstName: '', lastName: '' },
  description: '',
  details: [],
  id: '',
  _id: '',
  materials: [],
  name: '',
  service: [],
  updatedAt: ''
}

const useWorkOrderTemplates = () => {
  const {
    isLoading,
    isCreatingTemplate,
    workOrdersTemplates,
  } = useSelector(state => state.workOrders);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors, isValid, isDirty }, getValues, watch, control, reset } = useForm({
    mode: 'onBlur',
    defaultValues: {},
  });

  const nameValue = watch('name');
  const descriptionValue = watch('description');

  const [selectedTemplate, setSelectedTemplate] = useState(EMPTY_TEMPLATE);
  const [selectedTemplateKey, setSelectedTemplateKey] = useState('');

  const [fieldToEdit, setFieldToEdit] = useState(null);

  const {
    showModal: showNewFieldDialog,
    handleCloseModal: handleCloseNewFieldDialog,
    handleOpenModal: openNewFieldDialog,
  } = useBasicModal();

  const {
    showModal: showDeleteTemplateDialog,
    handleCloseModal: handleCloseDeleteTemplateDialog,
    handleOpenModal: handleOpenDeleteTemplateDialog,
  } = useBasicModal();

  useEffect(() => {
    dispatch(getWorkOrdersTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (nameValue !== undefined) updateTemplateProperty('name', nameValue);
  }, [nameValue]);

  useEffect(() => {
    if (descriptionValue !== undefined) updateTemplateProperty('description', descriptionValue);
  }, [descriptionValue]);

  const updateTemplateProperty = (key, value) => {
    setSelectedTemplate(prevTemplate => ({
      ...prevTemplate,
      [key]: value,
    }));
  };
  
  const handleOpenNewFieldDialog = (templateKey, field = null) => {
    console.log('templateKey', templateKey);
    console.log('field', field);
    
    setSelectedTemplateKey(templateKey);

    setFieldToEdit(field);

    openNewFieldDialog();
  }

  const handleTemplateChange = ({ target }) => {
    const selectedTemplate = workOrdersTemplates.find(
      ({ _id }) => _id === target.value
    )
    
    reset();
    setSelectedTemplate(selectedTemplate || EMPTY_TEMPLATE);
  };

  const addOrUpdateFieldInTemplate = (newField) => {
    setSelectedTemplate((prevTemplate) => {
      const existingFields = prevTemplate[selectedTemplateKey] || [];
  
      const updatedFields = existingFields.map((field) =>
        field._id === newField._id ? newField : field
      );
  
      return {
        ...prevTemplate,
        [selectedTemplateKey]: fieldToEdit
          ? updatedFields
          : [...existingFields, newField],
      };
    });
  
    setFieldToEdit(null);
  };

  const removeFieldFromTemplate = (fieldId, templateKey) => {
    setSelectedTemplate(prevTemplate => ({
      ...prevTemplate,
      [templateKey]: prevTemplate[templateKey].filter(field => field._id !== fieldId),
    }));
  };

  const sanitizeTemplate = (template) => {
    const sanitizeFields = (fields) =>
      fields.map(({ _id, id, options, ...rest }) => ({
        ...rest,
        options: options?.map(({ _id, id, ...optionRest }) => optionRest) || [],
      }));
  
    return {
      name: template.name,
      description: template.description,
      service: sanitizeFields(template.service),
      materials: sanitizeFields(template.materials),
      details: sanitizeFields(template.details),
    };
  };

  const handleCreateTemplate = async () => {
    try {
      const sanitizedTemplate = sanitizeTemplate(selectedTemplate);

      await dispatch(
        createWorkOrdersTemplate(sanitizedTemplate)
      ).unwrap();

      dispatch(getWorkOrdersTemplates());

      reset();
      setSelectedTemplate(EMPTY_TEMPLATE);

      toast.success(CREATE_WO_TEMPLATE_SUCCESS);
    } catch (error) {
      console.error(error);

      toast.error(CREATE_WO_TEMPLATE_FAIL);
    }
  };

  const handleDeleteTemplate = async () => {
    try {
      await dispatch(
        deleteWorkOrdersTemplate(selectedTemplate._id)
      ).unwrap();

      dispatch(getWorkOrdersTemplates());

      reset();
      setSelectedTemplate(EMPTY_TEMPLATE);

      toast.success(DELETE_WO_TEMPLATE_SUCCESS);
    } catch (error) {
      console.error(error);

      toast.error(DELETE_WO_TEMPLATE_FAIL);
    }
  };

  const handleCancelCreate = () => {
    navigate('/work-orders/new');
  };

  return {
    workOrdersTemplates,
    isLoading,
    isCreatingTemplate,
    selectedTemplate,
    handleCancelCreate,
    handleTemplateChange,
    handleCreateTemplate,
    handleDeleteTemplate,

    // Use Form
    register,
    handleSubmit,
    errors,
    isValid,
    isDirty,
    getValues,
    watch,
    control,

    // New Field Dialog
    fieldToEdit,
    setFieldToEdit,
    showNewFieldDialog,
    handleCloseNewFieldDialog,
    handleOpenNewFieldDialog,
    selectedTemplateKey,
    addOrUpdateFieldInTemplate,
    removeFieldFromTemplate,

    // Delete Dialog
    showDeleteTemplateDialog,
    handleCloseDeleteTemplateDialog,
    handleOpenDeleteTemplateDialog,
  };
};

export default useWorkOrderTemplates;
