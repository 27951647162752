// Modules
import { useEffect } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Loader from 'components/Loader';
import { getWorkOrderActivity } from 'features/workOrders/workOrderSlice';
import Card from 'components/common/Card';
import { Heading } from 'components/catalyst/heading';

// Utils
import wording from 'utils/wording';

export const getActionMessage = (action, changes) => {
  // First normalize the action string
  const normalizedAction = action?.toUpperCase()?.trim();
  
  switch (normalizedAction) {
    case 'CREATE_DOCUMENT':
      return `uploaded document "${changes?.documentName}"`;
    case 'DELETE_DOCUMENT':
      return 'removed a document';
    case 'CREATE':
      return 'created work order';
    case 'UPDATE':
      return 'updated work order';
    case 'DELETE':
      return 'deleted work order';
    default:
      // Use replaceAll instead of replace to catch all underscores
      // Also add null check for action
      return action?.toLowerCase()?.replaceAll('_', ' ') || 'performed an action';
  }
};

const WODetailActivityTab = ({ workOrder }) => {
  const { workOrderActivity, isFetchingActivity } = useSelector((state) => state.workOrders);

  const dispatch = useDispatch();

  const { WO_ACTIVITY_LOG_TITLE } = wording;

  useEffect(() => {
    dispatch(
      getWorkOrderActivity(workOrder._id)
    )
  }, [workOrder, dispatch]);

  return (
    <Card>
      <div className='flex justify-between items-center w-full'>
        <Heading level={2} className='pb-2 text-xl text-2xl'>
          {WO_ACTIVITY_LOG_TITLE}
        </Heading>
      </div>

      {isFetchingActivity ? (
        <div className='flex items-center justify-center w-full h-96'>
          <Loader />
        </div>
      ) : (
        <div className="flow-root py-4">
          <ul className="-mb-8">
            {workOrderActivity.map((log, idx) => (
              <li key={log._id}>
                <div className="relative pb-8">
                  {idx !== workOrderActivity.length - 1 && (
                    <span
                      className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200 dark:bg-gray-700"
                      aria-hidden="true"
                    />
                  )}
                  <div className="relative flex space-x-3">
                    <div>
                      <span 
                        className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white dark:ring-gray-900"
                        title={`${log.user.firstName} ${log.user.lastName} (${log.user.email})`}
                      >
                        <span className="text-white text-sm">
                          {log.user.firstName[0]}{log.user.lastName[0]}
                        </span>
                      </span>
                    </div>
                    <div className="flex flex-col md:flex-row min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-medium text-gray-900 dark:text-white">
                            {log.user.firstName} {log.user.lastName}
                          </span>{' '}
                          <span className="text-gray-400">({log.user.email})</span>{' '}
                          {getActionMessage(log.action, log.changes)}
                        </p>
                        {log.details && (
                          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                            {log.details}
                          </p>
                        )}
                      </div>
                      <div className="whitespace-nowrap pt-4 md:pt-0 text-right text-sm text-gray-500 dark:text-gray-400">
                        {format(new Date(log.createdAt), 'MMM d, yyyy h:mm a')}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Card>
  );
};

export default WODetailActivityTab;
