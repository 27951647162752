import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { axiosInstance } from '../../config/axios';
import { Input, InputGroup } from '../../components/catalyst/input';
import { Button, TouchTarget } from '../../components/catalyst/button';
import { 
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '../../components/catalyst/dialog';
import { PencilIcon, PlusIcon, TrashIcon, ChevronUpIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { featureConfig } from '../../config/features';

const Companies = () => {
  const auth = useSelector((state) => state.auth);
  const userRole = auth?.user?.user?.role;
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    address: {
      street: '',
      city: '',
      state: '',
      zipCode: '',
      country: ''
    },
    contact: {
      email: '',
      phone: '',
      website: ''
    },
    isActive: true
  });
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [isUserDialogOpen, setIsUserDialogOpen] = useState(false);
  const [userFormData, setUserFormData] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    role: 'TECHNICIAN', // Default role
    phone: '',
    company: '', // Will be selected from dropdown
    isActive: true
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    title: '',
    message: '',
    action: null,
    type: 'delete' // or 'info' for different styling
  });

  // Debug logs
  console.log('Auth state:', auth);
  console.log('User role:', userRole);
  console.log('Companies feature enabled:', featureConfig.companies);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      const { data } = await axiosInstance.get('/api/companies');
      setCompanies(data.companies);
      setUsers(data.users);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to fetch data');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedCompany) {
        await axiosInstance.put(`/api/companies/${selectedCompany._id}`, formData);
      } else {
        await axiosInstance.post('/api/companies', formData);
      }
      fetchCompanies();
      setIsDialogOpen(false);
      resetForm();
    } catch (err) {
      setError(err.response?.data?.message || 'Operation failed');
    }
  };

  const showConfirmation = (title, message, action, type = 'delete') => {
    setConfirmationDialog({
      isOpen: true,
      title,
      message,
      action,
      type
    });
  };

  const handleConfirm = async () => {
    if (confirmationDialog.action) {
      await confirmationDialog.action();
    }
    setConfirmationDialog(prev => ({ ...prev, isOpen: false }));
  };

  const handleDelete = async (company) => {
    if (company.userCount > 0) {
      showConfirmation(
        'Cannot Delete Company',
        `This company has ${company.userCount} users associated with it. Please reassign or delete these users first.`,
        null,
        'info'
      );
      return;
    }

    showConfirmation(
      'Delete Company',
      `Are you sure you want to delete ${company.name}? This action cannot be undone.`,
      async () => {
        try {
          await axiosInstance.delete(`/api/companies/${company._id}`);
          fetchCompanies();
        } catch (err) {
          const errorMessage = err.response?.data?.message || 'Failed to delete company';
          setError(errorMessage);
          showConfirmation('Error', errorMessage, null, 'error');
        }
      }
    );
  };

  const handleEdit = (company) => {
    setSelectedCompany(company);
    setFormData({
      name: company.name,
      address: {
        street: company.address?.street || '',
        city: company.address?.city || '',
        state: company.address?.state || '',
        zipCode: company.address?.zipCode || '',
        country: company.address?.country || ''
      },
      contact: {
        email: company.contact?.email || '',
        phone: company.contact?.phone || '',
        website: company.contact?.website || ''
      },
      isActive: company.isActive ?? true
    });
    setIsDialogOpen(true);
  };

  const resetForm = () => {
    setFormData({
      name: '',
      address: {
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: ''
      },
      contact: {
        email: '',
        phone: '',
        website: ''
      },
      isActive: true
    });
    setSelectedCompany(null);
  };

  const handleOpenChange = (open) => {
    setIsDialogOpen(open);
    if (!open) {
      resetForm();
    }
  };

  // Add sorting functionality
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedUsers = useMemo(() => {
    if (!sortConfig.key) return users;

    return [...users].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [users, sortConfig]);

  // Add this component for the sort header
  const SortHeader = ({ column, label }) => (
    <th 
      className="px-6 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider cursor-pointer hover:bg-zinc-100"
      onClick={() => requestSort(column)}
    >
      <div className="flex items-center space-x-1">
        <span>{label}</span>
        <div className="flex flex-col">
          <ChevronUpIcon 
            className={`h-3 w-3 ${
              sortConfig.key === column && sortConfig.direction === 'asc' 
                ? 'text-blue-500' 
                : 'text-zinc-400'
            }`}
          />
          <ChevronDownIcon 
            className={`h-3 w-3 ${
              sortConfig.key === column && sortConfig.direction === 'desc' 
                ? 'text-blue-500' 
                : 'text-zinc-400'
            }`}
          />
        </div>
      </div>
    </th>
  );

  const handleUserSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedUser) {
        await axiosInstance.put(`/api/users/update-user-as-admin/${selectedUser._id}`, userFormData);
      } else {
        await axiosInstance.post('/api/users', userFormData);
      }
      setIsUserDialogOpen(false);
      resetUserForm();
      fetchCompanies();
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Failed to save user';
      setError(errorMessage);
      showConfirmation('Error', errorMessage, null, 'error');
    }
  };

  const resetUserForm = () => {
    setUserFormData({
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      role: 'TECHNICIAN',
      phone: '',
      company: '',
      isActive: true
    });
    setSelectedUser(null);
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setUserFormData({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
      phone: user.phone || '',
      company: user.company,
      isActive: user.isActive
    });
    setIsUserDialogOpen(true);
  };

  const handleDeleteUser = async (user) => {
    showConfirmation(
      'Delete User',
      `Are you sure you want to delete ${user.firstName} ${user.lastName}? This action cannot be undone.`,
      async () => {
        try {
          await axiosInstance.delete(`/api/users/${user._id}`);
          fetchCompanies();
        } catch (err) {
          const errorMessage = err.response?.data?.message || 'Failed to delete user';
          setError(errorMessage);
          showConfirmation('Error', errorMessage, null, 'error');
        }
      }
    );
  };

  if (userRole !== 'SUPERADMIN') {
    console.log('Not SUPERADMIN, redirecting...');
    return <Navigate to="/dashboard" />;
  }

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Companies</h1>
        <Button variant="solid" onClick={() => setIsDialogOpen(true)}>
          <PlusIcon className="h-4 w-4 mr-2" />
          Add Company
        </Button>
      </div>

      <Dialog open={isDialogOpen} onClose={() => handleOpenChange(false)}>
        <div className="flex justify-between items-center">
          <DialogTitle>
            {selectedCompany ? 'Edit Company' : 'Add New Company'}
          </DialogTitle>
          <button
            onClick={() => handleOpenChange(false)}
            className="p-1 rounded-full hover:bg-zinc-100"
          >
            <XMarkIcon className="h-5 w-5 text-zinc-500" />
          </button>
        </div>
        <DialogBody>
          <form onSubmit={handleSubmit} className="space-y-4">
            <Input
              type="text"
              placeholder="Company Name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              required
            />
            
            <div className="space-y-2">
              <h3 className="text-sm font-medium">Address</h3>
              <Input
                type="text"
                placeholder="Street"
                value={formData.address.street}
                onChange={(e) => setFormData({
                  ...formData,
                  address: { ...formData.address, street: e.target.value }
                })}
              />
              <Input
                type="text"
                placeholder="City"
                value={formData.address.city}
                onChange={(e) => setFormData({
                  ...formData,
                  address: { ...formData.address, city: e.target.value }
                })}
              />
              <Input
                type="text"
                placeholder="State"
                value={formData.address.state}
                onChange={(e) => setFormData({
                  ...formData,
                  address: { ...formData.address, state: e.target.value }
                })}
              />
              <Input
                type="text"
                placeholder="ZIP Code"
                value={formData.address.zipCode}
                onChange={(e) => setFormData({
                  ...formData,
                  address: { ...formData.address, zipCode: e.target.value }
                })}
              />
              <Input
                type="text"
                placeholder="Country"
                value={formData.address.country}
                onChange={(e) => setFormData({
                  ...formData,
                  address: { ...formData.address, country: e.target.value }
                })}
              />
            </div>

            <div className="space-y-2">
              <h3 className="text-sm font-medium">Contact Information</h3>
              <Input
                type="email"
                placeholder="Email"
                value={formData.contact.email}
                onChange={(e) => setFormData({
                  ...formData,
                  contact: { ...formData.contact, email: e.target.value }
                })}
              />
              <Input
                type="tel"
                placeholder="Phone"
                value={formData.contact.phone}
                onChange={(e) => setFormData({
                  ...formData,
                  contact: { ...formData.contact, phone: e.target.value }
                })}
              />
              <Input
                type="url"
                placeholder="Website"
                value={formData.contact.website}
                onChange={(e) => setFormData({
                  ...formData,
                  contact: { ...formData.contact, website: e.target.value }
                })}
              />
            </div>

            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="isActive"
                checked={formData.isActive}
                onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })}
                className="rounded border-zinc-300"
              />
              <label htmlFor="isActive" className="text-sm">Active Company</label>
            </div>

            <DialogActions>
              <Button type="submit" variant="solid" className="w-full">
                {selectedCompany ? 'Update' : 'Create'}
              </Button>
            </DialogActions>
          </form>
        </DialogBody>
      </Dialog>

      <div className="rounded-lg border border-zinc-200">
        <table className="min-w-full divide-y divide-zinc-200">
          <thead className="bg-zinc-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider">ID</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider">Location</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider">Contact</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider">Users</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-zinc-200">
            {companies.map((company) => (
              <tr key={company._id} className={!company.isActive ? 'bg-zinc-50' : undefined}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-mono text-zinc-500">
                  {company._id}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-900">
                  <div className="flex items-center">
                    <span className={company.isActive ? '' : 'text-zinc-500'}>
                      {company.name}
                    </span>
                    {!company.isActive && (
                      <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-zinc-100 text-zinc-800">
                        Inactive
                      </span>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-900">
                  {[company.address?.city, company.address?.state, company.address?.country].filter(Boolean).join(', ')}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-900">
                  {company.contact?.email || company.contact?.phone || 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-900">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    company.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                  }`}>
                    {company.isActive ? 'Active' : 'Inactive'}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-900">
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                    {company.userCount} users
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-900 space-x-2">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => handleEdit(company)}
                  >
                    <PencilIcon className="h-4 w-4" />
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => handleDelete(company)}
                    className={`text-red-500 hover:text-red-700 ${
                      company.userCount > 0 ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={company.userCount > 0}
                    title={company.userCount > 0 ? 'Cannot delete company with active users' : 'Delete company'}
                  >
                    <TrashIcon className="h-4 w-4" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-8">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Users</h2>
          <Button variant="solid" onClick={() => setIsUserDialogOpen(true)}>
            <PlusIcon className="h-4 w-4 mr-2" />
            Add User
          </Button>
        </div>

        <Dialog open={isUserDialogOpen} onClose={() => {
          setIsUserDialogOpen(false);
          resetUserForm();
        }}>
          <div className="flex justify-between items-center">
            <DialogTitle>
              {selectedUser ? 'Edit User' : 'Add New User'}
            </DialogTitle>
            <button
              onClick={() => {
                setIsUserDialogOpen(false);
                resetUserForm();
              }}
              className="p-1 rounded-full hover:bg-zinc-100"
            >
              <XMarkIcon className="h-5 w-5 text-zinc-500" />
            </button>
          </div>
          <DialogBody>
            <form onSubmit={handleUserSubmit} className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <Input
                  type="text"
                  placeholder="First Name"
                  value={userFormData.firstName}
                  onChange={(e) => setUserFormData({ ...userFormData, firstName: e.target.value })}
                  required
                />
                <Input
                  type="text"
                  placeholder="Last Name"
                  value={userFormData.lastName}
                  onChange={(e) => setUserFormData({ ...userFormData, lastName: e.target.value })}
                  required
                />
              </div>

              <Input
                type="email"
                placeholder="Email"
                value={userFormData.email}
                onChange={(e) => setUserFormData({ ...userFormData, email: e.target.value })}
                required
              />

              {!selectedUser && (
                <Input
                  type="password"
                  placeholder="Password"
                  value={userFormData.password}
                  onChange={(e) => setUserFormData({ ...userFormData, password: e.target.value })}
                  required
                />
              )}

              <Input
                type="tel"
                placeholder="Phone"
                value={userFormData.phone}
                onChange={(e) => setUserFormData({ ...userFormData, phone: e.target.value })}
              />

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Role</label>
                <select
                  value={userFormData.role}
                  onChange={(e) => setUserFormData({ ...userFormData, role: e.target.value })}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  required
                >
                  <option value="">Select Role</option>
                  <option value="ADMIN">Admin</option>
                  <option value="DRAFTSMAN">Draftsman</option>
                  <option value="OFFICE">Office</option>
                  <option value="PROJECT_MANAGER">Project Manager</option>
                  <option value="SALES">Sales</option>
                  <option value="TECHNICIAN">Technician</option>
                  <option value="INSTALLER">Installer</option>
                  <option value="CUSTOMER">Customer</option>
                </select>
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Company</label>
                <select
                  value={userFormData.company}
                  onChange={(e) => setUserFormData({ ...userFormData, company: e.target.value })}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  required
                >
                  <option value="">Select Company</option>
                  {companies.map((company) => (
                    <option key={company._id} value={company._id}>
                      {company.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="userIsActive"
                  checked={userFormData.isActive}
                  onChange={(e) => setUserFormData({ ...userFormData, isActive: e.target.checked })}
                  className="rounded border-gray-300"
                />
                <label htmlFor="userIsActive" className="text-sm">Active User</label>
              </div>

              <DialogActions>
                <Button type="submit" variant="solid" className="w-full">
                  {selectedUser ? 'Update User' : 'Create User'}
                </Button>
              </DialogActions>
            </form>
          </DialogBody>
        </Dialog>

        <div className="rounded-lg border border-zinc-200">
          <table className="min-w-full divide-y divide-zinc-200">
            <thead className="bg-zinc-50">
              <tr>
                <SortHeader column="_id" label="ID" />
                <SortHeader column="firstName" label="First Name" />
                <SortHeader column="lastName" label="Last Name" />
                <SortHeader column="email" label="Email" />
                <SortHeader column="companyName" label="Company" />
                <th className="px-6 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-zinc-200">
              {sortedUsers.map((user) => (
                <tr key={user._id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-mono text-zinc-500">
                    {user._id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-900">
                    {user.firstName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-900">
                    {user.lastName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-900">
                    {user.email}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-900">
                    {user.companyName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-900 space-x-2">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => handleEditUser(user)}
                    >
                      <PencilIcon className="h-4 w-4" />
                    </Button>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => handleDeleteUser(user)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <TrashIcon className="h-4 w-4" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Dialog 
        open={confirmationDialog.isOpen} 
        onClose={() => setConfirmationDialog(prev => ({ ...prev, isOpen: false }))}
      >
        <div className="flex justify-between items-center">
          <DialogTitle>
            {confirmationDialog.title}
          </DialogTitle>
          <button
            onClick={() => setConfirmationDialog(prev => ({ ...prev, isOpen: false }))}
            className="p-1 rounded-full hover:bg-zinc-100"
          >
            <XMarkIcon className="h-5 w-5 text-zinc-500" />
          </button>
        </div>
        <DialogBody>
          <p className="text-sm text-zinc-600">
            {confirmationDialog.message}
          </p>
          <DialogActions>
            <div className="flex justify-end space-x-2 mt-4">
              <Button
                variant="outline"
                onClick={() => setConfirmationDialog(prev => ({ ...prev, isOpen: false }))}
              >
                Cancel
              </Button>
              {confirmationDialog.action && (
                <Button
                  variant="solid"
                  onClick={handleConfirm}
                  className={
                    confirmationDialog.type === 'delete' 
                      ? 'bg-red-600 hover:bg-red-700' 
                      : ''
                  }
                >
                  Confirm
                </Button>
              )}
            </div>
          </DialogActions>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default Companies; 