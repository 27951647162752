// Modules
import { v4 as uuidv4 } from 'uuid';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

// UI
import { Button } from "components/catalyst/button";
import { Dialog, DialogActions, DialogBody } from "components/catalyst/dialog";
import { Strong, Text } from "components/catalyst/text";
import { XMarkIcon, PlusIcon } from '@heroicons/react/20/solid';
import { ErrorMessage, Field, Label } from "components/catalyst/fieldset";
import { Input } from "components/catalyst/input";
import { Select } from "components/catalyst/select";

// Utils
import wording from "utils/wording";
import { filedTypes, typesWithOptions, typesWithPlaceholder } from 'utils/form-utils';

const {
  TEMPLATE_FORM_ADD_FIELD,
  GLOBAL_CANCEL,
  ERROR_REQUIRED_FIELD,
  TEMPLATE_FORM_UPDATE_FIELD,
} = wording;

const WOTemplateNewFieldDialog = ({
  showNewFieldDialog,
  handleCloseNewFieldDialog,
  addOrUpdateFieldInTemplate,
  fieldToEdit,
  setFieldToEdit,
}) => {
  const { register, handleSubmit, formState: { errors, isValid }, setValue, watch, reset } = useForm({
    mode: 'onBlur',
    defaultValues: {},
  });

  const [options, setOptions] = useState([{ id: uuidv4(), value: '' }]);

  const selectedType = watch('type');

  useEffect(() => {
    if (fieldToEdit) {
      setValue('type', fieldToEdit.type);
      setValue('label', fieldToEdit.label);
      setValue('placeholder', fieldToEdit.placeholder || '');
      setOptions(fieldToEdit.options?.map(opt => ({ id: opt.id, value: opt.label })) || []);
    }
  }, [fieldToEdit, setValue]);

  const handleAddOption = () => {
    setOptions(prevOptions => [...prevOptions, { id: uuidv4(), value: '' }]);
  };
  
  const handleRemoveOption = (id) => {
    setOptions(prevOptions => prevOptions.filter(option => option.id !== id));
  };
  
  const handleChangeOption = (id, newValue) => {
    setOptions(prevOptions =>
      prevOptions.map(option =>
        option.id === id ? { ...option, value: newValue } : option
      )
    );
  };

  const isAddFieldDisabled = () => {
    if (!isValid) return true;
  
    if (typesWithOptions.includes(selectedType)) {
      const nonEmptyOptions = options.filter(option => option.value.trim() !== '');
      if (nonEmptyOptions.length === 0) return true;
  
      const uniqueOptions = new Set(nonEmptyOptions.map(option => option.value.trim()));
      if (uniqueOptions.size !== nonEmptyOptions.length) return true;
    }
  
    return false;
  };

  const handleClose = () => {
    handleCloseNewFieldDialog();
    reset({ type: '', label: '', placeholder: '' });
    setOptions([{ id: uuidv4(), value: '' }]);
    setFieldToEdit(null);
  };

  const onSubmit = (data) => {
    const formattedOptions = options
      .filter(option => option.value.trim() !== '')
      .map(option => ({
        label: option.value,
        value: option.value.trim().toLowerCase().replace(/\s+/g, '_'),
        _id: uuidv4(),
        id: uuidv4(),
      }));

      const newField = {
        _id: fieldToEdit?._id || uuidv4(),
        label: data.label,
        name: data.label.trim().toLowerCase().replace(/\s+/g, '_'),
        type: data.type,
        placeholder: typesWithPlaceholder.includes(data.type) ? data.placeholder || '' : undefined,
        options: typesWithOptions.includes(data.type) ? formattedOptions : [],
      };

    handleClose();
    addOrUpdateFieldInTemplate(newField);
  }

  return (
    <Dialog open={showNewFieldDialog} onClose={handleClose}>
      <div className='flex items-center justify-between'>
        <Strong>
          {TEMPLATE_FORM_ADD_FIELD}
        </Strong>

        <Button
          plain
          onClick={handleClose}
        >
          <XMarkIcon />
        </Button>
      </div>

      <DialogBody>
        <div className='space-y-4'>
          <Field>
            <Label>
              {'Field Type'}
            </Label>

            <Select
              {...register('type', {
                required: ERROR_REQUIRED_FIELD 
              }
            )}>
              <option value="">{""}</option>

              {filedTypes.map((option) => (
                <option
                  key={option}
                  value={option}
                >
                  {option}
                </option>
              ))}
            </Select>

            {errors.type && (
              <ErrorMessage>{errors.type.message}</ErrorMessage>
            )}
          </Field>

          <Field>
            <Label>{'Field Name'}</Label>

            <Input
              type="text"
              {...register('label', {
                required: ERROR_REQUIRED_FIELD,
              })}
            />

            {errors.label && (
              <ErrorMessage>{errors.label.message}</ErrorMessage>
            )}
          </Field>

          {typesWithPlaceholder.includes(selectedType) && (
            <Field>
              <Label>{'Field Placeholder'}</Label>

              <Input
                type="text"
                {...register('placeholder', {
                  required: ERROR_REQUIRED_FIELD,
                })}
              />

              {errors.name && (
                <ErrorMessage>{errors.name.message}</ErrorMessage>
              )}
            </Field>
          )}

          {typesWithOptions.includes(selectedType) && (
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <Text>
                  <Strong>
                    {'Options'}
                  </Strong>
                </Text>

                <Button 
                  outline
                  onClick={handleAddOption}
                >
                  <PlusIcon className="fill-blue-500" />
                  {'Add Option'}
                </Button>
              </div>

              {options.map(({ id, value }) => (
                <Field key={id} className="flex items-center space-x-2">
                  <Input
                    type="text"
                    value={value}
                    onChange={(e) => handleChangeOption(id, e.target.value)}
                  />
                  <Button plain onClick={() => handleRemoveOption(id)}>
                    <XMarkIcon className="w-4 h-4 text-red-500" />
                  </Button>
                </Field>
              ))}
            </div>
          )}

        </div>
      </DialogBody>
      
      <DialogActions>
        <Button onClick={handleClose} outline>
          {GLOBAL_CANCEL}
        </Button>

        <Button
          onClick={handleSubmit(onSubmit)}
          outline
          disabled={isAddFieldDisabled()}
        >
          {fieldToEdit ? TEMPLATE_FORM_UPDATE_FIELD : TEMPLATE_FORM_ADD_FIELD}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WOTemplateNewFieldDialog;
