// UI
import { DescriptionDetails, DescriptionList, DescriptionTerm } from 'components/catalyst/description-list';
import { Heading } from 'components/catalyst/heading';
import { Input } from 'components/catalyst/input';
import Card from 'components/common/Card';
import StatusBadge from 'components/StatusBadge';
import StatusSelectRegister from 'components/StatusSelect/StatusSelectRegister';
import { formatDate } from 'utils/commons';

// Utils
import wording from 'utils/wording';

const StatusCard = ({
  workOrder,
  isEditing,
  register,
  watch,
}) => {
  const {
    WO_DETAIL_STATUS,
    WO_DETAIL_REQUESTED_ON,
    WO_DETAIL_START_DATE,
    WO_DETAIL_END_DATE,
    WO_DETAIL_CREATED_BY,
  } = wording;

  const startDate = watch('startDate');

  return (
    <Card className='break-inside-avoid h-min'>
      <Heading level={2} className='pb-2 text-xl md:text-2xl'>
        {WO_DETAIL_STATUS}
      </Heading>

      <DescriptionList>
        <DescriptionTerm>
          {WO_DETAIL_REQUESTED_ON}
        </DescriptionTerm>

        <DescriptionDetails>
          {new Date(workOrder?.dateRequested).toLocaleDateString()}
        </DescriptionDetails>

        <DescriptionTerm>
          {WO_DETAIL_CREATED_BY}
        </DescriptionTerm>

        <DescriptionDetails>
          {`${workOrder?.createdBy?.firstName} ${workOrder?.createdBy?.lastName}`}
        </DescriptionDetails>

        <DescriptionTerm>
          {WO_DETAIL_STATUS}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <StatusSelectRegister register={register} />
          ) : (
            <StatusBadge status={workOrder?.status || 'pending'} />
          )}
        </DescriptionDetails>
          
        <DescriptionTerm>
          {WO_DETAIL_START_DATE}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <Input
              type="date"
              name="startDate"
              {...register('startDate')}
              // value={editedData.startDate ? editedData.startDate.slice(0, 10) : ''}
            />
          ) : (
            <>
              {formatDate(workOrder?.startDate)}
            </>
          )}
        </DescriptionDetails>

        <DescriptionTerm>
          {WO_DETAIL_END_DATE}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <Input
              type="date"
              {...register('endDate')}
              min={startDate ? startDate : ''}
              // value={editedData.endDate ? editedData.endDate.slice(0, 10) : ''}
              // min={editedData.startDate ? editedData.startDate.slice(0, 10) : ''}
            />
          ) : (
            <>
              {formatDate(workOrder?.endDate)}
            </>
          )}
        </DescriptionDetails>  
      </DescriptionList>
    </Card>
  );
};

export default StatusCard;
