// Module dependencies
import {  forwardRef } from 'react';
import { AllCommunityModule, ModuleRegistry } from "ag-grid-community";

// UI
import StatusBadge from 'components/StatusBadge';
import { Strong, Text } from 'components/catalyst/text';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { Button } from 'components/catalyst/button';
import { BadgeButton } from 'components/catalyst/badge';
import BaseTable from "components/BaseTable";
import { Select } from 'components/catalyst/select';
import Spinner from 'components/common/Spinner';

// Utils
import wording from 'utils/wording';
import { formatCurrency, formatDate } from 'utils/commons';
import { generatePDF } from 'utils/work-orders';
import { WORK_ORDER_STATUSES } from 'components/workOrder/utils';

// Theme
ModuleRegistry.registerModules([AllCommunityModule]);

const WorkOrdersTable = forwardRef(
  ({ 
    workOrders,
    activeWorkOrders,
    includeCompletedWO,
    handleFilter,
    handleViewWorkOrder,
    onDragStarted,
    onDragStopped,
    onGridReady,
    onPaginationChanged,
    editingWOStatusId,
    handleUpdateStatus,
  }, ref) => {
    const {
      WORK_ORDERS_CLIENT,
      WORK_ORDERS_DATE_REQUESTED,
      WORK_ORDERS_ADDRESS,
      WORK_ORDERS_STATUS,
      WORK_ORDERS_AMOUNT_DUE,
      WORK_ORDERS_CREATED_BY,
      WORK_ORDERS_ASSIGNED_TO,
      WORK_ORDERS_TAGS,
    } = wording;

    const statusOptions = [
      { value: WORK_ORDER_STATUSES.PENDING, label: 'Pending' },
      { value: WORK_ORDER_STATUSES.ON_SCHEDULE, label: 'On Schedule' },
      { value: WORK_ORDER_STATUSES.IN_PROGRESS, label: 'In Progress' },
      { value: WORK_ORDER_STATUSES.COMPLETED, label: 'Completed' },
      { value: WORK_ORDER_STATUSES.CANCELLED, label: 'Cancelled' },
    ];
  
    const columnDefs = [
      {
        headerName: WORK_ORDERS_CLIENT,
        field: 'client',
        colId: 'client',
        minWidth: 200,
        cellRenderer: ({ data }) => (
          <div className='flex flex-col'>
            <Strong className='leading-4'>{data.client?.name}</Strong>
            <Text className='!text-[12px] truncate'>{data.client?.email}</Text>
          </div>
        ),
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          if (valueA.name === valueB.name) return 0;
          return (valueA.name > valueB.name) ? 1 : -1;
        },
        getQuickFilterText: ({ data }) => {
          return `${data.client?.name} ${data.client?.email}`;
        }
      },
      {
        headerName: WORK_ORDERS_DATE_REQUESTED.slice(5),
        field: 'dateRequested',
        width: 120,
        cellRenderer: ({ value }) => (
          <Text>
            {formatDate(value)}
          </Text>
        ),
      },
      {
        headerName: WORK_ORDERS_ADDRESS,
        field: 'address.formattedAddress',
        colId: 'address.formattedAddress',
        minWidth: 200,
        cellRenderer: ({ value }) => (
          <Text className='truncate'>
            {value || 'N/A'}
          </Text>
        ),
      },
      {
        headerName: WORK_ORDERS_STATUS,
        field: 'status',
        width: 160,
        editable: true,
        cellRenderer: ({ data, value }) => (
          <>
            {editingWOStatusId ===  data._id ? (
              <div className='pl-4'>
                <Spinner />
              </div>
            ) : (
              <StatusBadge
                status={value || 'pending'}
                ref={ref}
              />
            )}
          </>
        ),
        cellEditor: ({ value, data, stopEditing }) => (
          <Select
            name="status"
            value={value}
            ref={(ref) => {
              if (!ref) return;
      
              ref.onclick = (e) => {
                e.stopPropagation();
                e.preventDefault();
              };
            }}
            onChange={(e) => {
              handleUpdateStatus(e.target.value, data, stopEditing)
            }}
          >
            {statusOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        ),
      },
      {
        headerName: WORK_ORDERS_AMOUNT_DUE,
        field: 'amountDue',
        width: 130,
        valueFormatter: ({ value }) => formatCurrency(value),
      },
      {
        headerName: WORK_ORDERS_CREATED_BY,
        field: 'createdBy',
        minWidth: 160,
        valueGetter: ({ data }) =>
          data.createdBy
            ? `${data.createdBy.firstName} ${data.createdBy.lastName}`
            : 'N/A',
      },
      {
        headerName: WORK_ORDERS_ASSIGNED_TO,
        field: 'assignedTo',
        minWidth: 160,
        valueGetter: ({ data }) =>
          data.assignedTo
            ? `${data.assignedTo.firstName} ${data.assignedTo.lastName}`
            : 'Unassigned',
      },
      // {
      //   headerName: 'Project',
      //   field: 'assignedToProject.name',
      //   valueGetter: ({ data }) => data.assignedToProject?.name || 'No Project',
      // },
      {
        headerName: WORK_ORDERS_TAGS,
        field: 'tags',
        minWidth: 160,
        cellRenderer: ({ data }) => (
          <div className="flex flex-wrap gap-1 max-h-[54px] md:overflow-auto">
            {data.tags?.map((tag, index) => (
              <BadgeButton
                key={index}
                color='blue'
                ref={(ref) => {
                  if (!ref) return;
          
                  ref.onclick = (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleFilter(tag);
                  };
                }}
              >
                {tag}
              </BadgeButton>
            ))}
          </div>
        ),
      },
      {
        headerName: '',
        field: 'actions',
        filter: false,
        resizable: false,
        sortable: false,
        width: 60,
        cellClass: 'flex items-center h-full justify-center w-full',
        pinned: 'right',
        cellRenderer: ({ data }) => (
          <Button
            ref={(ref) => {
              if (!ref) return;
      
              ref.onclick = (e) => {
                e.stopPropagation();
                generatePDF(data);
              };
            }}
            outline
          >
            <ArrowDownTrayIcon />
          </Button>
        ),
      },
    ];
  
    return (
      <div>
        <BaseTable
          ref={ref}
          rowData={includeCompletedWO ? workOrders : activeWorkOrders}
          columnDefs={columnDefs}
          maintainColumnOrder={true}
          onRowClicked={handleViewWorkOrder}
          onDragStarted={onDragStarted}
          onDragStopped={onDragStopped}
          onGridReady={onGridReady}
          onPaginationChanged={onPaginationChanged}
        />
      </div>
    );
  }
);

export default WorkOrdersTable;
