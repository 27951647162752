// UI
import Breadcrumb from "components/Breadcrumb";
import { Button } from "components/catalyst/button";
import { Heading } from "components/catalyst/heading";
import LoadingButton from "components/common/LoadingButton";
import { ChevronLeftIcon } from '@heroicons/react/20/solid';

// Utils
import wording from "utils/wording";
import useWorkOrderTemplates from "hooks/useWorkOrderTemplates";
// import WOTemplatesHeader from "components/workOrderTemplates/WOTemplatesHeader";
import WorkOrderTemplatesForm from "components/workOrderTemplates/WOTemplatesForm";
// import Card from "components/common/Card";

const {
  SIDEBAR_TEMPLATES,
  SIDEBAR_WORK_ORDERS,
  CREATE_WO_TEMPLATE_CREATE,
  CREATE_WO_TEMPLATE_CREATING,
  GLOBAL_BACK,
} = wording;

const WorkOrderTemplatesView = () => {
  const pages = [
    { name: SIDEBAR_WORK_ORDERS, href: '/work-orders', current: false },
    { name: SIDEBAR_TEMPLATES, href: '/work-orders/templates', current: false },
  ];

  const workOrderTemplatesHandlers = useWorkOrderTemplates();

  const {
    isValid,
    isCreatingTemplate,
    handleCreateTemplate,
    handleCancelCreate,
  } = workOrderTemplatesHandlers;

  return (
    <div className='flex flex-col gap-6'>
      <Breadcrumb pages={pages} />

      <div className='flex flex-col md:flex-row md:justify-between gap-4'>
        <Heading className='text-xl md:text-2xl'>{CREATE_WO_TEMPLATE_CREATE}</Heading>

        <div className='flex md:justify-end items-center gap-2'>
          <LoadingButton
            color='green'
            type='submit'
            disabled={!isValid || isCreatingTemplate}
            isLoading={isCreatingTemplate}
            label={CREATE_WO_TEMPLATE_CREATE}
            loadingLabel={CREATE_WO_TEMPLATE_CREATING}
            className='whitespace-nowrap'
            onClick={handleCreateTemplate}
          />

          <Button
            outline
            onClick={handleCancelCreate}
          >
            <ChevronLeftIcon />
            {GLOBAL_BACK}
          </Button>
        </div>
      </div>

      {/* <WOTemplatesHeader {...workOrderTemplatesHandlers} /> */}
      
      <WorkOrderTemplatesForm workOrderTemplatesHandlers={workOrderTemplatesHandlers} />
    </div>
  );
};

export default WorkOrderTemplatesView;
