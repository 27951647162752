// Module dependencies
// UI
import { Button } from 'components/catalyst/button';
import { Dialog, DialogActions, DialogBody, DialogTitle } from 'components/catalyst/dialog';
import { Text } from 'components/catalyst/text';

// Utils
import wording from 'utils/wording';

const WOTemplateDeleteDialog = ({
  isOpen,
  handleCloseModal,
  handleDeleteTemplate,
}) => {
  const {
    GLOBAL_CANCEL,
    GLOBAL_DELETE,
    TEMPLATE_DELETE_DIALOG_TITLE,
    TEMPLATE_DELETE_DIALOG_DESCRIPTION
  } = wording;

  const handleConfirmDelete = () => {
    handleDeleteTemplate();
  
    handleCloseModal();
  };

  return (
    <Dialog open={isOpen} onClose={handleCloseModal}>
      <DialogTitle>{TEMPLATE_DELETE_DIALOG_TITLE}</DialogTitle>

      <DialogBody>
        <Text>
          {TEMPLATE_DELETE_DIALOG_DESCRIPTION}
        </Text>
      </DialogBody>
      
      <DialogActions>
        <Button plain onClick={handleCloseModal} className='cursor-pointer'>
          {GLOBAL_CANCEL}
        </Button>

        <Button onClick={handleConfirmDelete} color='rose' className='cursor-pointer'>
          {GLOBAL_DELETE}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WOTemplateDeleteDialog;
