// Module dependencies
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getMessages, sendMessage } from "features/workOrders/workOrderSlice";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { searchUsers } from "features/users/userSlice";

// UI
import { Heading } from "components/catalyst/heading";
import Loader from "components/Loader";
import WODetailMessagesEmpty from "./WODetailMessagesEmpty";
import { Box } from "components/common/StyledBoxs";
import { Input } from "components/catalyst/input";
import LoadingButton from "components/common/LoadingButton";
import WODetailUserMention from "./WODetailUserMention";
import MessageFeed from "components/MessageFeed";
import { PaperAirplaneIcon } from '@heroicons/react/24/outline'

// Utils
import wording from "utils/wording";

const {
  WO_DETAIL_MESSAGES,
  ERROR_REQUIRED_FIELD,
  WO_MESSAGES_PLACEHOLDER,
  WO_MESSAGES_FAILED,
  WO_MESSAGES_SUCCESS,
  WO_MESSAGES_SEND, 
  WO_MESSAGES_SENDING,
} = wording;

const WODetailMessagesList = () => {
  const { id } = useParams();

  const {
    isFetchingMessages,
    isSendingMessage,
    workOrderMessages,
  } = useSelector((state) => state.workOrders);
  
  const dispatch = useDispatch();

  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const isSelectingMention = useRef(false);

  const [mentionUsers, setMentionUsers] = useState([]);
  const [mentionListVisible, setMentionListVisible] = useState(false);

  const { register, handleSubmit, setValue, watch, formState: { isValid }, reset } = useForm({
    defaultValues: {
      newMessage: "",
    },
  });

  const newMessageValue = watch("newMessage");

  useEffect(() => {
    dispatch(
      getMessages(id)
    );
  }, [dispatch, id])

  useEffect(() => {
    if (isSelectingMention.current) {
      isSelectingMention.current = false;
      
      return;
    }

    const handleMentions = async (value) => {
      const lastAtSymbol = value.lastIndexOf("@");
      
      if (lastAtSymbol !== -1) {
        const query = value.slice(lastAtSymbol + 1);
        
        if (query.length > 0) {
          const users = await dispatch(
            searchUsers(query)
          ).unwrap();

          setMentionUsers(users);
          
          if (users.length > 0 && !mentionListVisible) {
            setMentionListVisible(true);
          }
        } else {
          setMentionListVisible(false);
        }
      } else {
        setMentionListVisible(false);
      }
    };

    if (newMessageValue) {
      handleMentions(newMessageValue);
    }
  }, [newMessageValue, dispatch]);

  const handleMentionSelect = (selectedUser) => {
    isSelectingMention.current = true;

    const lastAtSymbol = newMessageValue.lastIndexOf("@");

    const newValue =
      newMessageValue.slice(0, lastAtSymbol) +
      `@${selectedUser.firstName}${selectedUser.lastName} `;

      setValue("newMessage", newValue, { shouldValidate: false, shouldDirty: false })
  
    setMentionListVisible(false);
  
    inputRef.current?.focus();
  };

  const onSubmit = async (data) => {
    try {
      await dispatch(
        sendMessage({
          workOrderId: id,
          message: data.newMessage
        })
      ).unwrap();

      toast.success(WO_MESSAGES_SUCCESS);

      await dispatch(
        getMessages(id)
      );

      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });

      reset();
    } catch (error) {
      console.log(error);
      
      toast.error(WO_MESSAGES_FAILED);
    }
  }

  return (
    <div className="flex flex-col h-[calc(100vh-300px)]">
      <Box className="flex-1 px-4 py-4 overflow-y-auto space-y-2">
        <div className="w-full p-2">
          <Heading level={2} className='pb-2 text-xl md:text-2xl'>
            {WO_DETAIL_MESSAGES}
          </Heading>
        </div>

        {isFetchingMessages ? (
          <div className="w-full flex-1 h-80">
            <Loader />
          </div>
        ) : (
          <div className="px-2 space-y-2">
            {workOrderMessages.length === 0 ? (
              <WODetailMessagesEmpty />
            ) : (
              <MessageFeed messages={workOrderMessages} />
            )}

            <div ref={messagesEndRef} />
          </div>
        )}
      </Box>

      <div className="pt-4">
        {mentionListVisible && (
          <WODetailUserMention
            mentions={mentionUsers}
            onSelect={handleMentionSelect}
          />
        )}

        <form onSubmit={handleSubmit(onSubmit)} className="flex gap-3">
          <Input
            ref={inputRef}
            type="text"
            {...register('newMessage', {
              required: ERROR_REQUIRED_FIELD
            })}
            placeholder={WO_MESSAGES_PLACEHOLDER}
          />

          <LoadingButton
            type="submit"
            disabled={isSendingMessage || !isValid}
            color='green'
            isLoading={isSendingMessage}
            label={WO_MESSAGES_SEND}
            loadingLabel={WO_MESSAGES_SENDING}
            endIcon={<PaperAirplaneIcon />}
          />
        </form>
      </div>
    </div>
  );
};

export default WODetailMessagesList;
