// UI
import { Description, ErrorMessage, Field, Label } from 'components/catalyst/fieldset';
import { Heading } from 'components/catalyst/heading';
import { Input } from 'components/catalyst/input';
import Card from 'components/common/Card';
import wording from 'utils/wording';
import WOTemplateDinamicCard from './WOTemplateDinamicCard';
import { templateKeys } from 'utils/form-utils';
import WOTemplateNewFieldDialog from './WOTemplateNewFieldDialog';
import { Textarea } from 'components/catalyst/textarea';
import { Strong, Text } from 'components/catalyst/text';
import WOTemplateDeleteDialog from './WOTemplateDeleteDialog';
import LoadingButton from 'components/common/LoadingButton';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Select } from 'components/catalyst/select';
import { Divider } from 'components/catalyst/divider';

const WorkOrderTemplatesForm = ({
  workOrderTemplatesHandlers
}) => {
  const {
    ERROR_REQUIRED_FIELD,
    TEMPLATE_FORM_NAME,
    TEMPLATE_FORM_DESCRIPTION,
    TEMPLATE_FORM_INFO,
    TEMPLATE_FORM_NAME_DESCRIPTION,
    TEMPLATE_FORM_DESCRIPTION_DETAIL,
    TEMPLATE_FORM_FIELDS,
    CREATE_WO_TEMPLATE_DESCRIPTION,
    TEMPLATE_DELETE_DIALOG_TITLE,
    GLOBAL_DELETING,
    TEMPLATE_FORM_SELECT,
  } = wording;

  const {
    register,
    errors,
    selectedTemplate,
    workOrdersTemplates,
    handleTemplateChange,
    handleDeleteTemplate,
    showDeleteTemplateDialog,
    handleCloseDeleteTemplateDialog,
    handleOpenDeleteTemplateDialog,
    isDeletingTemplate,
  } = workOrderTemplatesHandlers;

  return (
    <>
      <div className='flex flex-col space-y-4'>
        <Strong className='text-lg'>
          {TEMPLATE_FORM_INFO}
        </Strong>

        <div>
          <div className='flex flex-col w-full gap-1'>
            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-4 sm:pb-6'>
              <Text>
                <Strong>
                  {TEMPLATE_FORM_SELECT}
                </Strong>
              </Text>

              <div className="mt-2 sm:col-span-2 sm:mt-0 space-y-4 ">
                <div className='flex justify-start items-center gap-4 pt-2'>
                  {workOrdersTemplates.length > 0 && (
                    <Select
                      value={selectedTemplate ? selectedTemplate._id : ''}
                      onChange={handleTemplateChange}
                      className='max-w-lg'
                    >
                      <option value={''}>{'Empty Template'}</option>

                      {workOrdersTemplates.map(({ name, _id }) => (
                        <option 
                          key={_id} 
                          value={_id}
                        >
                          {name}
                        </option>
                      ))}
                    </Select>
                  )}

                  {selectedTemplate._id && (
                    <LoadingButton
                      outline
                      color='red'
                      disabled={isDeletingTemplate}
                      isLoading={isDeletingTemplate}
                      label={TEMPLATE_DELETE_DIALOG_TITLE}
                      loadingLabel={GLOBAL_DELETING}
                      className='whitespace-nowrap'
                      onClick={handleOpenDeleteTemplateDialog}
                      startIcon={<TrashIcon className='stroke-red-500' />}
                    />
                  )}
                </div>

                <Text>{CREATE_WO_TEMPLATE_DESCRIPTION}</Text>
              </div>
            </div>

            <Divider />

            <div className="space-y-6 w-full pt-4">
              <Field className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-2'>
                <Label>{TEMPLATE_FORM_NAME}</Label>

                <div className="mt-2 sm:col-span-2 sm:mt-0 space-y-4">
                  <Input
                    type="text"
                    {...register('name', {
                      required: ERROR_REQUIRED_FIELD,
                    })}
                  />

                  <Description>
                    {TEMPLATE_FORM_NAME_DESCRIPTION}
                  </Description>

                  {errors.name && (
                    <ErrorMessage>{errors.name.message}</ErrorMessage>
                  )}
                </div>
              </Field>

              <Divider />

              <Field className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4'>
                <Label>{TEMPLATE_FORM_DESCRIPTION}</Label>

                <div className="mt-2 sm:col-span-2 sm:mt-0 space-y-4">
                  <Textarea
                    rows={2}
                    {...register("description")}
                  />

                  <Description>
                    {TEMPLATE_FORM_DESCRIPTION_DETAIL}
                  </Description>

                  {errors.description && (
                    <ErrorMessage>{errors.description.message}</ErrorMessage>
                  )}
                </div>
              </Field>

              <Divider />
            </div>
          </div>
        </div>
      </div>

      {selectedTemplate && templateKeys.map((key) => (
        <WOTemplateDinamicCard
          key={key}
          {...workOrderTemplatesHandlers}
          templateKey={key}
        />
      ))}

      <WOTemplateNewFieldDialog {...workOrderTemplatesHandlers} />

      <WOTemplateDeleteDialog
        isOpen={showDeleteTemplateDialog}
        handleCloseModal={handleCloseDeleteTemplateDialog}
        handleDeleteTemplate={handleDeleteTemplate}
      />
    </>
  );
};

export default WorkOrderTemplatesForm;
