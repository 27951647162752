// Module dependencies
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { login } from "features/auth/authSlice";

// UI
import { Fieldset, FieldGroup, Field, Label } from "components/catalyst/fieldset";
import { Input, InputGroup } from "components/catalyst/input";
import { Text } from "components/catalyst/text";
import { Checkbox, CheckboxField } from "components/catalyst/checkbox";
import LoadingButton from "components/common/LoadingButton";
import { LockClosedIcon } from '@heroicons/react/24/outline';

// Utils
import wording from "utils/wording";

const {
  LOGIN_EMAIL_LABEL,
  LOGIN_PASSWORD_LABEL,
  LOGIN_BUTTON,
  SIGNING_IN_BUTTON,
  LOGIN_REGISTER_ACCOUNT,
  LOGIN_FORGOT_PASSWORD,
  ERROR_REQUIRED_FIELD,
} = wording;

const LoginForm = () => {
  const { isLoading } = useSelector(state => state.auth)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { register, handleSubmit, setValue, control, formState: { isValid } } = useForm({
    defaultValues: {
      email: '',
      password: '',
      rememberMe: false,
    }
  });

  useEffect(() => {
    const lastUserEmail = localStorage.getItem("nimax_auth_last_user");
    const rememberUser = localStorage.getItem("nimax_auth_remember_user");
  
    if (lastUserEmail) {
      setValue("email", lastUserEmail);
    }

    if (rememberUser) {
      setValue("rememberMe", true);
    }
  }, [setValue]);

  const onSubmit = async (data) => {
    try {
      const result = await dispatch(login({
        email: data.email,
        password: data.password
      })).unwrap();
    
      if (!result.error) {
        navigate('/dashboard');
      }

      if (data.rememberMe) {
        localStorage.setItem('nimax_auth_last_user', data.email);
        localStorage.setItem('nimax_auth_remember_user', 'true');
      } else {
        localStorage.removeItem('nimax_auth_last_user');
        localStorage.removeItem('nimax_auth_remember_user');
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className="bg-white dark:bg-zinc-900 py-8 px-4 shadow-lg border border-gray-200 dark:border-gray-800 sm:rounded-lg sm:px-10">
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <Fieldset>
          <FieldGroup>
            <Field>
              <Label>{LOGIN_EMAIL_LABEL}</Label>

              <Input
                type="email"
                {...register("email", {
                  required: ERROR_REQUIRED_FIELD,
                })}
              />
            </Field>

            <CheckboxField className='!mt-4 !gap-x-3'>
              <Controller
                name='rememberMe'
                control={control}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={(checked) => field.onChange(checked)}
                  />
                )}
              />

              <Text>{'Remember my email for future logins'}</Text>
            </CheckboxField>

            <Field>
              <Label>{LOGIN_PASSWORD_LABEL}</Label>

              <InputGroup>
                <Input
                  type="password"
                  {...register("password", {
                    required: ERROR_REQUIRED_FIELD,
                  })}
                />

                <LockClosedIcon />
              </InputGroup>
            </Field>

            <LoadingButton
              disabled={isLoading || !isValid}
              type='submit'
              isLoading={isLoading}
              label={LOGIN_BUTTON}
              loadingLabel={SIGNING_IN_BUTTON}
              className='w-full'
            />
          </FieldGroup>
        </Fieldset>
      </form>

      <div className="mt-6">
        <div className="flex items-center justify-between">
          <Text className='cursor-not-allowed'>
            {LOGIN_REGISTER_ACCOUNT}
          </Text>

          <Link to='/forgot-password'>
            <Text className='cursor-pointer hover:text-zinc-900 dark:hover:text-zinc-100'>
              {LOGIN_FORGOT_PASSWORD}
            </Text>
          </Link>        
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
