'use client'

import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Button } from 'components/catalyst/button';
import { Heading } from 'components/catalyst/heading';
import Loader from 'components/Loader';
import wording from 'utils/wording';
import NotificationsList from './NotificationsList';

const {
  NOTIFICATION_TITLE,
  NOTIFICATION_MARK_ALL_AS_READ,
} = wording;

const NotificationsDialog = ({
  notifications,
  isFetchingNotifications,
  isUpdatingNotifications,
  handleMarkAsRead,
  handleMarkAllAsRead,

  showNotificationsModal,
  handleCloseNotificationsModal,
}) => {

  return (
    <Dialog open={showNotificationsModal} onClose={handleCloseNotificationsModal} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col divide-y divide-zinc-200 dark:divide-zinc-800 bg-white dark:bg-zinc-900 shadow-xl">
                <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6 px-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <Heading className='text-xl '>
                      {NOTIFICATION_TITLE}
                    </Heading>
                    
                    <Button
                      plain
                      onClick={handleCloseNotificationsModal}
                    >
                      <XMarkIcon aria-hidden="true" className="!size-5" />
                    </Button>
                  </div>

                  <div className="relative mt-6 flex-1">
                    {(isFetchingNotifications || isUpdatingNotifications) ? (
                      <div className='flex items-center justify-center w-full h-96'>
                        <Loader />
                      </div>
                    ) : (
                      <>
                        <NotificationsList
                          notifications={notifications}
                          onMarkAsRead={handleMarkAsRead}
                          handleCloseNotificationsModal={handleCloseNotificationsModal}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="flex shrink-0 justify-end px-4 py-4">
                  <Button
                    color='blue'
                    onClick={handleMarkAllAsRead}
                  >
                    {NOTIFICATION_MARK_ALL_AS_READ}
                  </Button>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default NotificationsDialog;
