// Modules
import useWorkOrdersDashboard from "hooks/useWorkOrdersDashboard";

// UI
import Breadcrumb from "components/Breadcrumb";
import { Heading } from "components/catalyst/heading";
import WODashboardDeadlines from "components/workOrderDashboard/WODashboardDeadlines";
import WODashboardKpis from "components/workOrderDashboard/WODashboardKpis";
import WODashboardPriorityChart from "components/workOrderDashboard/WODashboardPriorityChart";
import WODashboardStatusChart from "components/workOrderDashboard/WODashboardStatusChart";
import WODashboardTotals from "components/workOrderDashboard/WODashboardTotals";
import WODashboardLastActivity from "components/workOrderDashboard/WODashboardLastActivity";
import Loader from "components/Loader";

// Utils
import wording from "utils/wording";

const {
  SIDEBAR_WORK_ORDERS,
  WORK_ORDER_DASH_DISTRIBUTION_HEADING,
} = wording;

const WorkOrderDashboard = () => {
  const dashboardHandlers = useWorkOrdersDashboard();

  const pages = [
    { name: SIDEBAR_WORK_ORDERS, href: '/work-orders', current: false },
  ];
  
  const {
    workOrderKPIs,
    isLoading,
  } = dashboardHandlers;

  return (
    <div className='flex flex-col gap-6'>
      <Breadcrumb pages={pages} />

      {/* <Heading>{SIDEBAR_WORK_ORDERS}</Heading> */}

      <div className='flex flex-col gap-10'>
        <WODashboardDeadlines {...dashboardHandlers} />

        <WODashboardKpis {...dashboardHandlers}  />

        <div>
          <Heading className='pb-6 text-xl md:text-2xl'>
            {WORK_ORDER_DASH_DISTRIBUTION_HEADING}
          </Heading>

          {isLoading ? (
            <div className="w-full h-[200px] flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            <div className="flex flex-col md:flex-row gap-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <WODashboardStatusChart workOrderKPIs={workOrderKPIs} />

                <WODashboardPriorityChart workOrderKPIs={workOrderKPIs} />
              </div>

              <WODashboardTotals workOrderKPIs={workOrderKPIs} />
            </div>
          )}
        </div>

        <WODashboardLastActivity />
      </div>
    </div>
  );
};

export default WorkOrderDashboard;
