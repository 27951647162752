// UI
import { Button } from "components/catalyst/button";
import { Heading } from "components/catalyst/heading";
import { ChevronLeftIcon, PhoneIcon, EnvelopeIcon, ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import LoadingButton from "components/common/LoadingButton";
import { Text } from "components/catalyst/text";

// Utils
import wording from "utils/wording";
import { generatePDF } from "utils/work-orders";

const WODetailsHeader = ({
  isEditing,
  handleSave,
  handleEdit,
  handleCancel,
  handleBackToWorkOrder,
  isLoading,
  workOrder,
  handleSubmit,
  onSubmit,
}) => {
  const {
    WO_DETAIL_TITLE,
    WO_DETAIL_SAVE_CHANGES,
    WO_DETAIL_EDIT,
    WO_DETAIL_CANCEL,
    WORK_ORDERS_BACK_TO_WO,
    WORK_ORDERS_DOWNLOAD_PDF,
    GLOBAL_DASH,
  } = wording;

  return (
    <div className='flex flex-col flex-wrap md:flex-row md:justify-between'>
      <div className="flex flex-col gap-1">
        <Heading className='capitalize text-xl md:text-2xl'>
          {workOrder?.client?.name || WO_DETAIL_TITLE}
        </Heading>

        <div className="flex flex-col md:flex-row gap-1">
          {workOrder?.client?.phone && (
            <div className="flex gap-2">
              <PhoneIcon className="w-4 text-zinc-500 dark:text-zinc-400"  />

              <Text>{workOrder.client.phone}</Text>
            </div>
          )}

          {workOrder?.client?.email && workOrder.client?.phone && (
            <Text className='px-2 hidden md:block'>{GLOBAL_DASH}</Text>
          )}

          {workOrder?.client?.email && (
            <div className="flex gap-2">
              <EnvelopeIcon className="w-4 text-zinc-500 dark:text-zinc-400" />

              <Text>{workOrder.client.email}</Text>
            </div>
          )}
        </div>
      </div>

      <div className='flex flex-wrap gap-2 items-center pt-6 md:pt-0'>
        {isEditing ? (
          <LoadingButton
            color={'green'}
            disabled={isLoading}
            isLoading={isLoading}
            label={WO_DETAIL_SAVE_CHANGES}
            loadingLabel={WO_DETAIL_SAVE_CHANGES}
            onClick={handleSubmit(onSubmit)}
            className='whitespace-nowrap w-[150px]'
          />
        ) : (
          <Button
            onClick={handleEdit}
            color='blue'
            className='whitespace-nowrap w-[150px]'
          >
            {WO_DETAIL_EDIT}
          </Button>
        )}

        <Button
          onClick={() => generatePDF(workOrder)}
          outline
          className='whitespace-nowrap'
        >
          <ArrowDownTrayIcon />
          <span className="hidden md:block">
            {WORK_ORDERS_DOWNLOAD_PDF}
          </span>
        </Button>

        <Button
          outline
          onClick={() => {
            if (isEditing) {
              handleCancel();
            } else {
              handleBackToWorkOrder();
            }
          }}
          className='whitespace-nowrap'
        >
          {!isEditing && <ChevronLeftIcon />}

          {isEditing && WO_DETAIL_CANCEL}

          {!isEditing && (
            <span className="hidden md:block">
              {WORK_ORDERS_BACK_TO_WO}
            </span>
          )}
        </Button>
      </div>
    </div>
  );
};

export default WODetailsHeader;
