// UI
import ClientInformationForm from 'components/workOrder/ClientInformationForm';
import Card from 'components/common/Card';
import WorkInfoForm from 'components/workOrder/WorkInfoForm';
import ServiceInformation from 'components/workOrder/ServiceInformation';
import DinamicCreateForm from 'components/workOrder/DinamicCreateForm';
import DocumentsForm from 'components/workOrder/DocumentsForm';

// Utils
import { templateKeys } from 'utils/form-utils';

const CreateWorkOrderForm = ({ createWorkOrderHandlers }) => {
  const {
    selectedTemplate,
  } = createWorkOrderHandlers;
  
  return (
    <form>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
        <div className='space-y-4'>
          <Card>
            <ClientInformationForm {...createWorkOrderHandlers} />
          </Card>

          <Card>
            <ServiceInformation {...createWorkOrderHandlers} />
          </Card>

          <Card>
            <WorkInfoForm {...createWorkOrderHandlers} />
          </Card>
          
          <Card>
            <DocumentsForm {...createWorkOrderHandlers} />
          </Card>
        </div>

        <div className='space-y-4'>
          {selectedTemplate && templateKeys.map(
            (key) =>
              selectedTemplate[key]?.length > 0 && (
                <DinamicCreateForm
                  key={key}
                  {...createWorkOrderHandlers}
                  templateKey={key}
                />
              )
          )}
        </div>
      </div>
      {/* <div className="columns-2 gap-4 space-y-4"> */}
    </form>
  );
};

export default CreateWorkOrderForm;
