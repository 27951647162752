// Modules
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { getWorkOrderLastActivity } from 'features/workOrders/workOrderSlice';

// UI
import { Heading } from 'components/catalyst/heading';
import { Strong, Text } from 'components/catalyst/text';
import { Box } from 'components/common/StyledBoxs';
import Loader from 'components/Loader';

// Utils
import { getActionMessage } from 'components/workOrderDetails/WODetailActivityTab';
import wording from 'utils/wording';

const WODashboardLastActivity = () => {
  const { workOrderLastActivity, isFetchingActivity } = useSelector((state) => state.workOrders);

  const dispatch = useDispatch();

  const { WORK_ORDER_DASH_ACTIVITY } = wording;

  useEffect(() => {
    dispatch(
      getWorkOrderLastActivity()
    )
  }, [dispatch]);

  return (
    <div className='space-y-6'>
      <Heading className='text-xl md:text-2xl'>
        {WORK_ORDER_DASH_ACTIVITY}
      </Heading>

      <Box className='p-8'>
        {isFetchingActivity ? (
          <div className='flex items-center justify-center w-full h-96'>
            <Loader />
          </div>
        ) : (
          <div className="flow-root">
            <ul className="-mb-8">
              {workOrderLastActivity.map((log, idx) => (
                <li key={log._id}>
                  <div className="relative pb-8">
                    {idx !== workOrderLastActivity.length - 1 && (
                      <span
                        className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200 dark:bg-gray-700"
                        aria-hidden="true"
                      />
                    )}
                    <div className="relative flex space-x-3">
                      <div>
                        <span 
                          className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white dark:ring-gray-900"
                          title={`${log.user.firstName} ${log.user.lastName} (${log.user.email})`}
                        >
                          <Text>
                            <Strong className='!text-white'>
                              {log.user.firstName[0]}{log.user.lastName[0]}
                            </Strong>
                          </Text>
                        </span>
                      </div>

                      <div className="flex min-w-0 flex-col md:flex-row md:flex-1 md:justify-between md:space-x-4">
                        <div className='flex flex-col md:flex-row gap-4 md:gap-12'>
                          <div className='flex flex-col justify-center gap-0.5'>
                            <Text className='!leading-4'>
                              <Strong>
                                {log.user.firstName} {log.user.lastName}
                              </Strong>
                            </Text>

                            <Text className='!leading-4'>
                              {log.user.email}
                            </Text>
                          </div>

                          <div className='flex justify-start items-center border-zinc-950/10 dark:border-white/10'>
                            <Text className='capitalize'>
                              {getActionMessage(log.action, log.changes)}
                            </Text>
                          </div>

                          {log.details && (
                            <div className='flex justify-start items-center border-l pl-8 border-zinc-950/10 dark:border-white/10'>
                              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                                {log.details}
                              </p>
                            </div>
                          )}
                        </div>

                        <Text className="whitespace-nowrap md:text-right text-left">
                          {format(new Date(log.createdAt), 'MMM d, yyyy h:mm a')}
                        </Text>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Box>
    </div>
  );
};

export default WODashboardLastActivity;
