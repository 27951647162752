// UI
import { Badge } from 'components/catalyst/badge';
import { Doughnut } from 'react-chartjs-2'
import { getStatusColor } from './WODashboardKpis';
import { Heading } from 'components/catalyst/heading';

// Utils
import wording from 'utils/wording';
import StatusBadge from 'components/StatusBadge';
import { Box } from 'components/common/StyledBoxs';

const WODashboardPriorityChart = ({
  workOrderKPIs
}) => {
  const {
    WORK_ORDER_DASH_PRIORITY_DIST,
    WORK_ORDER_DASH_NO_PRIORITY,
  } = wording;

  const workOrderStatusChart = {
    labels: Object.entries(workOrderKPIs.workOrdersByPriority).map(([status]) => (status)),
    datasets: [{
      data: Object.values(workOrderKPIs.workOrdersByPriority),
      backgroundColor: [
        'rgba(239, 68, 68, 0.5)',    // Red-500
        'rgba(234, 179, 8, 0.5)',    // Yellow-500
        'rgba(34, 197, 94, 0.5)',   // Green-500
        'rgba(113, 113, 122, 0.5)',  // Zinc-500
      ],
      borderColor: [
        'rgba(239, 68, 68, 0.8)',    // Red-500
        'rgba(234, 179, 8, 0.8)',    // Yellow-500
        'rgba(34, 197, 94, 0.8)',   // Green-500
        'rgba(113, 113, 122, 0.8)',  // Zinc-500
      ],
      borderWidth: 2
    }]
  };

  const chartOptions = {
    layout: {
      padding: 20
    },
    plugins: {
      legend: {
        display: false
      },
    },
  };

  return (
    <Box className='p-8'>
      <Heading>
        {WORK_ORDER_DASH_PRIORITY_DIST}
      </Heading>

      <div className='px-12 py-4 max-h-[360px] flex justify-center'>
        <Doughnut
          data={workOrderStatusChart}
          options={{
            ...chartOptions,
            plugins: {
              ...chartOptions.plugins,
            }
          }}
        />
      </div>

      <div className='flex gap-4 flex-wrap'>
        {Object.entries(workOrderKPIs.workOrdersByPriority).map(([status, count]) => (
          <StatusBadge key={status} status={status} className='whitespace-nowrap px-2 capitalize'>
            {`${status === 'undefined' ? WORK_ORDER_DASH_NO_PRIORITY : status} - ${count}`}
          </StatusBadge>
        ))}
      </div>
    </Box>
  );
};

export default WODashboardPriorityChart;
