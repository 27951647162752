// Modules
import { Fragment } from 'react';

// UI
import { DescriptionDetails, DescriptionList, DescriptionTerm } from 'components/catalyst/description-list';
import { Heading } from 'components/catalyst/heading';
import Card from 'components/common/Card';

// Utils
import wording from 'utils/wording';
import { renderDetailFormFields, renderDetailValues } from 'utils/form-utils';

const DinamicCard = ({
  workOrder,
  isEditing,
  workOrdersTemplate,
  register,
  control,
  errors,
  templateKey,
}) => {
  const {
    CREATE_WO_DINAMIC_SERVICE,
    CREATE_WO_DINAMIC_MATERIALS,
    CREATE_WO_DINAMIC_DETAILS,
  } = wording;

  const getCardTitle = () => {
    const keyToTitleMapper ={
      service: CREATE_WO_DINAMIC_SERVICE,
      materials: CREATE_WO_DINAMIC_MATERIALS,
      details: CREATE_WO_DINAMIC_DETAILS,
    }

    return keyToTitleMapper[templateKey] || keyToTitleMapper.details;
  };

  return (
    <Card className='break-inside-avoid h-min'>
      <Heading level={2} className='pb-2 text-xl md:text-2xl'>
        {getCardTitle()}
      </Heading>

      <DescriptionList>
        {workOrdersTemplate[templateKey].map((field) => (
          <Fragment key={field.name}>
            <DescriptionTerm>
              {field.label}
            </DescriptionTerm>

            <DescriptionDetails>
              {isEditing ? (
                renderDetailFormFields(field, templateKey, register, control, errors)
              ) : (
                <>
                  {renderDetailValues(field, templateKey, workOrder, workOrdersTemplate)}
                </>
              )}
            </DescriptionDetails>
          </Fragment>
        ))}
      </DescriptionList>
    </Card>
  );
};

export default DinamicCard;
