// Module dependencies
import { useNavigate } from 'react-router-dom';
import useCalendar from 'hooks/useCalendar';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

// UI
import { Button } from 'components/catalyst/button';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu  } from 'components/catalyst/dropdown';
import { Heading } from 'components/catalyst/heading';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/16/solid'
import { ChevronLeftIcon } from '@heroicons/react/20/solid';

// Utils
import wording from 'utils/wording';

const GlobalSchedule = ({ workOrders }) => {
  const navigate = useNavigate();

  const {
    calendarRef,
    scheduleView,
    currentDate,
    handlePrevious,
    handleNext,
    handleViewChange,
  } = useCalendar();

  const {
    WORK_ORDERS_SCHEDULE,
    GLOBAL_MONTH,
    GLOBAL_WEEK,
    WORK_ORDERS_BACK_TO_WO,
  } = wording;

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return { bg: '#FEF3C7', border: '#F59E0B', text: '#B45309' };
      case 'on-schedule':
        return { bg: '#E0F2FE', border: '#0EA5E9', text: '#0369A1' };
      case 'in-progress':
        return { bg: '#DBEAFE', border: '#3B82F6', text: '#1D4ED8' };
      case 'completed':
        return { bg: '#D1FAE5', border: '#10B981', text: '#047857' };
      case 'cancelled':
        return { bg: '#FEE2E2', border: '#EF4444', text: '#B91C1C' };
      default:
        return { bg: '#F3F4F6', border: '#9CA3AF', text: '#374151' };
    }
  };
  
  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'on-schedule':
        return 'bg-sky-100 text-sky-800';
      case 'in-progress':
        return 'bg-blue-100 text-blue-800';
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const calendarEvents = workOrders
    .filter(wo => wo.startDate && wo.endDate)
    .map(wo => ({
      id: wo._id,
      title: wo.client?.name || 'Unnamed Client',
      start: wo.startDate.split('T')[0],
      end: wo.endDate.split('T')[0],
      backgroundColor: getStatusColor(wo.status).bg,
      borderColor: getStatusColor(wo.status).border,
      textColor: getStatusColor(wo.status).text,
      classNames: ['shadow-sm', 'border'],
      extendedProps: {
        status: wo.status,
        address: wo.address?.formattedAddress,
        dateRequested: wo.dateRequested,
        workPerformed: wo.workPerformed
      }
    }));

  return (
    <div>
      <div className='flex flex-col md:flex-row md:items-center md:justify-between gap-4 pb-4'>
        <Heading className='text-xl md:text-2xl'>{WORK_ORDERS_SCHEDULE}</Heading>

        <div className='flex items-center justify-between gap-2'>
          <Dropdown>
            <DropdownButton outline className='capitalize'>
              {scheduleView}
              <ChevronDownIcon />
            </DropdownButton>

            <DropdownMenu>
              <DropdownItem onClick={() => handleViewChange('month')}>
                {GLOBAL_MONTH}
              </DropdownItem>

              <DropdownItem onClick={() => handleViewChange('week')}>
                {GLOBAL_WEEK}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <Button outline onClick={handlePrevious}>
            <ArrowLongLeftIcon />
          </Button>

          <Button outline onClick={handleNext}>
            <ArrowLongRightIcon />
          </Button>

          <Button
            outline
            onClick={() => navigate('/work-orders/list')}
          >
            <ChevronLeftIcon />

            <span className='hidden md:block'>
              {WORK_ORDERS_BACK_TO_WO}
            </span>

            <span className='md:hidden block'>
              {WORK_ORDERS_BACK_TO_WO.slice(0, 4)}
            </span>
          </Button>
        </div>
      </div>

      <div className="h-[70vh] bg-white">
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView={scheduleView === 'month' ? 'dayGridMonth' : 'timeGridWeek'}
          initialDate={currentDate}
          events={calendarEvents}
          headerToolbar={{
            left: '',
            center: 'title',
            right: ''
          }}
          views={{
            dayGridMonth: {
              dayMaxEvents: 4,
            },
            timeGridWeek: {
              dayMaxEvents: true,
              slotMinTime: '06:00:00',
              slotMaxTime: '20:00:00',
              expandRows: true,
              allDaySlot: true,
              slotEventOverlap: false,
              dayHeaderFormat: { weekday: 'short', day: 'numeric' },
              scrollTime: '06:00:00',
            }
          }}
          eventContent={(eventInfo) => (
            <div className={`
              flex items-center gap-1 p-1 w-full
              ${scheduleView === 'week' ? 'min-h-[40px]' : 'min-h-[24px]'}
            `}>
              <div className="flex flex-col flex-grow overflow-hidden">
                <span className={`
                  font-medium truncate
                  ${scheduleView === 'week' ? 'text-sm' : 'text-xs'}
                `}>
                  {eventInfo.event.title}
                </span>
                {scheduleView === 'week' && (
                  <span className="text-xs truncate text-gray-600">
                    {eventInfo.event.extendedProps.address}
                  </span>
                )}
              </div>
              <span className={`
                text-xs px-1.5 rounded-full whitespace-nowrap
                ${getStatusBadgeClass(eventInfo.event.extendedProps.status)}
              `}>
                {eventInfo.event.extendedProps.status}
              </span>
            </div>
          )}
          eventClick={(info) => {
            navigate(`/work-orders/${info.event.id}`);
          }}
          eventDidMount={(info) => {
            const isWeekView = scheduleView === 'week';
            const height = isWeekView ? '40px' : '24px';
            
            info.el.style.height = height;
            info.el.style.padding = '0px';
            info.el.style.margin = isWeekView ? '4px 0' : '1px 0';
            info.el.style.overflow = 'hidden';
            
            // Enhanced tooltip with more information
            info.el.title = `
              Client: ${info.event.title}
              Status: ${info.event.extendedProps.status}
              Address: ${info.event.extendedProps.address}
              Requested On: ${new Date(info.event.extendedProps.dateRequested).toLocaleDateString()}
              Work: ${info.event.extendedProps.workPerformed || 'N/A'}
            `.trim();
          }}
          height="100%"
          className="fc-tailwind"
          firstDay={1}
          weekends={true}
          fixedWeekCount={false}
          showNonCurrentDates={false}
        />
      </div>
    </div>
  );
};

export default GlobalSchedule;
